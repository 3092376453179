import React, {useState, useEffect} from "react";
import './map.css';

import {
    useParams
} from "react-router-dom";

import { usePubNub } from 'pubnub-react';

import { API, Geo } from "aws-amplify";
import { useSyncExternalStore } from "react";

function AccuRangeMap(props) {
    let interval;

    const pubnub = usePubNub();
    const imageFlagBaseUrl = "https://accupin-app-storage212237-dev.s3.amazonaws.com/public/club_flags/";
    const [userUUID, setUserUUID] = useState("");
    const [currentLati, setCurrentLati] = useState(0);
    const [currentLong, setCurrentLong] = useState(0);
    const [mess, setMess] = useState("");

    const _loadScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('async', 'true');
            script.setAttribute('src', src);
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    }
    
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });
    
    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }
    
    useEffect(() => {

        window.addEventListener('resize', detectSize);
        getRotationData();
        return () => {
            window.removeEventListener('resize', detectSize);
            clearInterval(interval);
        }

    }, [windowDimenion]);

    const getRotationData = () => {

        const queryParams = new URLSearchParams(window.location.search);
       
        const regionLati = Number(queryParams.get('regionLati'));
        const regionLong = Number(queryParams.get('regionLong'));
        const upperLeftLati = Number(queryParams.get('upperLeftLati'));
        const upperLeftLong = Number(queryParams.get('upperLeftLong'));
        global.longFactor = Number(queryParams.get('longFactor'));
        const userName = queryParams.get('userName');
        const customFlagEnabled = queryParams.get('customFlagEnabled');
        const orientation = Number(queryParams.get('orientation'));
        setUserUUID(userName);
        const club = queryParams.get('club');
        global.flagName = "https://accupin-app-storage212237-dev.s3.amazonaws.com/public/club_flags/default_flag/flag.png";
        if(customFlagEnabled == "true"){
            global.flagName = imageFlagBaseUrl + club + "/flag.png";
        }
       
        console.log("flag link: ", global.flagName);
        global.userName = userName;

        // console.log(parentCourse, childCourse, pinNumber); // 55 test null

        console.log("customFlagEnabled: ");
        console.log(customFlagEnabled);

        

        console.log("regionLati Data: ");
        console.log(regionLati);

        console.log("upperLeftLati Data: ");
        console.log(upperLeftLati);

        console.log("userName: ");
        console.log(userName);

        console.log("club : ");
        console.log(club);
        console.log(club.toLowerCase());

        global.currentLati = 10;
        global.currentLong = 10;
        global.fontLarger = false;
        global.pressLati = 0;
        global.pressLong = 0;

        let centerLati, centerLong, deltaLati, deltaLong, heading;

        global.upperLeftLati = upperLeftLati;
        global.upperLeftLong = upperLeftLong;

        centerLati = regionLati;
        centerLong = regionLong;

        let latiFactor = 122000;
        let longFactor = global.longFactor;

        deltaLati = Math.abs(global.upperLeftLati - centerLati);

        deltaLong = Math.abs(global.upperLeftLong - centerLong);

        let deltaLatiAlt = deltaLati * latiFactor / longFactor;

        let detla = deltaLatiAlt > deltaLong ? deltaLatiAlt : deltaLong;

        console.log("detal lati and long: ", deltaLatiAlt, deltaLong);

        let zoom = Math.round(Math.log(360 / detla) / Math.LN2 / 20) 

        heading = getHeadingFromUL(centerLati, centerLong, global.upperLeftLati, global.upperLeftLong);

        heading = heading - 180;
        heading = orientation * -1;

        console.log("Distance and heading : ", heading, zoom, detla);

        global.currentLati = 40.964595;
        global.currentLong = -72.725875;

        const flagData = queryParams.get('flagData');
        const lastFlagData = JSON.parse(flagData);
        let pointArray = lastFlagData;
        console.log("last flag data: ", pointArray);

        let tempKeys = Object.keys(pointArray);

        var tempArray = [];

        for(let i = 0; i < tempKeys.length; i++){

            const itemKey = tempKeys[i];

            const item = pointArray[itemKey];

            // console.log("item: ", item);

            const lati = item["latitude"];

            const long = item["longitude"];

            let distance = 0;

            if(global.currentLong != 0){

                distance = getDistance(lati, long, global.currentLati, global.currentLong);

            }

            distance = Math.round(distance).toString();

            let color = "white";

            if(item.hasOwnProperty("color")){

                color = item["color"];

            }

            let fontSize = 24;

            if(item.hasOwnProperty("fontSize")){

                fontSize = item["fontSize"];

            }

            let newItem = {
                flagName: tempKeys[i],

                latitude: Number(lati),

                longitude: Number(long),

                color: color,

                fontSize: fontSize,

                distance: distance

            }

            tempArray.push(newItem);			

        }

        global.holeViewData = tempArray;
        

        console.log("New Hole View Data: ", tempArray);

        const edgeData = queryParams.get('edgedata');
        const lastEdgeData = JSON.parse(edgeData);
        let edgeArray = lastEdgeData;
        console.log("last edge data: ", edgeArray);

        let tempEdgeKeys = Object.keys(edgeArray);

        var tempArray = [];

        for(let i = 0; i < tempEdgeKeys.length; i++){

            const itemKey = tempEdgeKeys[i];

            const item = edgeArray[itemKey];

            // console.log("item: ", item);

            const lati = item["latitude"];

            const long = item["longitude"];

            let distance = 0;

            if(global.currentLong != 0){

                distance = getDistance(lati, long, global.currentLati, global.currentLong);

            }

            distance = Math.round(distance).toString();

            let color = "white";

            if(item.hasOwnProperty("color")){

                color = item["color"];

            }

            let fontSize = 24;

            if(item.hasOwnProperty("fontSize")){

                fontSize = item["fontSize"];

            }

            let newItem = {
                flagName: tempKeys[i],

                latitude: Number(lati),

                longitude: Number(long),

                color: color,

                fontSize: fontSize,

                distance: distance

            }

            tempArray.push(newItem);			

        }

        global.holeEdgeData = tempArray;
        

        console.log("New Egde View Data: ", tempArray);

        initMap(centerLati, centerLong, deltaLati, deltaLong, heading);
               


    }

    useEffect(() => {

        

        // window.addEventListener("message", (event) => {
        //     const origin = event.origin;
        //     const message = event.data;
            
        //     console.log("message::>>>:::", message);
        //     console.log("message::>>>:::", origin);
        //     if(origin === "http://192.168.0.100:3000") {
        //         console.log("message:::::", message);
        //         setMess(message);
        //     }
        //  })

        const queryParams = new URLSearchParams(window.location.search);
       

		if (pubnub) {

		  	// pubnub.setUUID(userName);

			pubnub.subscribe({ channels: ["location"] });

			return () => {

				pubnub.removeListener();

				pubnub.unsubscribeAll();

			};

		};

	}, [pubnub]);


    const initMap = (centerLati, centerLong, deltaLati, deltaLong, heading) => {
        Promise.all([
          _loadScript('https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js'),
        ]).then(() => {
    
            console.log(window.mapkit);
        
            var mapkit = window.mapkit;

            // const tokenID = "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlpDTjVWOTlaUk4ifQ.eyJpc3MiOiJISEs1OTIzTVc4IiwiaWF0IjoxNjk3NjAwMDI5LCJleHAiOjE3MjkxMjMyMDAsIm9yaWdpbiI6Imh0dHA6Ly9sb2NhbGhvc3Q6MzAwMCJ9.ZYBAkVfvvJMDtshS7uYMH4FFHld-KAasRHH7HYtJ6ufbjHhiiTKxZql41jNBNJF_U5MvsMIIoWZzALgV2IfWfw";  //local
            const tokenID = "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlpDTjVWOTlaUk4ifQ.eyJpc3MiOiJISEs1OTIzTVc4IiwiaWF0IjoxNjk3NTgyMDc0LCJleHAiOjE3MjkxMjMyMDAsIm9yaWdpbiI6Imh0dHA6Ly8zLjIxMy40NS4xNTIifQ.DERDRRRMYWeDQ6fyseg6m0wF2JUrDinb4F1XJyeH283ZQbKungmuCxfwJZgCu1f5Hn3twptus3LN-q9pNux4Zg";      // server
            mapkit.init({
                authorizationCallback: function(done) {
                    done(tokenID);
                }
            });
        
            var map = new mapkit.Map("map");
            var center = new mapkit.Coordinate(centerLati, centerLong);
            var span = new mapkit.CoordinateSpan(deltaLati* 1.2, deltaLong * 1.2); // latitude delta, longitude delta
            var region = new mapkit.CoordinateRegion(center, span);
            map.region = region;
            map.mapType = "satellite";
            map.showsZoomControl = true;
            // map.showsMapTypeControl = true;
            map.showsUserLocation = true;
            // var zoomRange = new mapkit.CameraZoomRange(2, 1500000)
            // map.cameraZoomRange = zoomRange;
            // map.cameraBoundary = region;
            map.rotation = -1 * heading;

            var rotationOption = {
                data: {fontColor: 'white', color: "white", flagUrl:  global.flagName}
            }

            let rotationCoordinate = new mapkit.Coordinate(centerLati, centerLong);
            var rotationAnnotation = new mapkit.Annotation(rotationCoordinate, rotationFactory, rotationOption);
            // map.addAnnotation(rotationAnnotation);

           
            var pointAnnotation;
        
            map.addEventListener('single-tap', function(event){
                console.log(event);
                const {pointOnPage} = event;
                console.log(pointOnPage);
        
                const pressPointCoords = map.convertPointOnPageToCoordinate(pointOnPage);

                global.pressLati = pressPointCoords.latitude;
                global.pressLong = pressPointCoords.longitude;

                if(pointAnnotation){
                    map.removeAnnotation(pointAnnotation);
                }

                var distance = "";

                if(global.currentLati){
                    distance = Math.round(getDistance(global.currentLati, global.currentLong, pressPointCoords.latitude, pressPointCoords.longitude));

                    // let toRotation = Math.round(getDistance(global.rotationLati, global.rotationLong, pressPointCoords.latitude, pressPointCoords.longitude));

                    distance = distance.toString(); 
                }

                var pointOption = {
                    data: {fontColor: 'black', color: "black", distance: distance, fontLarger:true}
                }
                pointAnnotation = new mapkit.Annotation(pressPointCoords, dotFactory, pointOption);
                map.addAnnotation(pointAnnotation);

                console.log(pressPointCoords);
            });

            var holeViewAnnotationArray = [];
            var edgeAnnotationArray = [];
            var tableAnnotationArray = [];
   
            var currentLocationAnnotation;

            let acoordinate, annotation;

            window.addEventListener("message", message => {
                // const tempMessage = JSON.parse(message);
                if(message){
                    setMess(message.data);
                    const currentLocation = JSON.parse(message.data);
                    // let currentLocation = {
                    //     latitude: "47.20057",
                    //     longitude: "-122.574723"
                    // }
                    const {latitude, longitude} = currentLocation;
                    setCurrentLati(latitude);
                    setCurrentLong(longitude);
                    console.log("message: ", latitude);
    
                    global.currentLati = Number(latitude);
                    global.currentLong = Number(longitude);
    
                    if(pointAnnotation){
                        map.removeAnnotation(pointAnnotation);
                    }
    
                    var distance = ""
    
                    if(global.currentLati != 0 && global.pressLati != 0){
                        distance = Math.round(getDistance(global.currentLati, global.currentLong, global.pressLati, global.pressLong));
    
                        // let toRotation = Math.round(getDistance(global.rotationLati, global.rotationLong, global.pressLati, global.pressLong));
    
                        distance = distance.toString(); 
                    }
    
                    var pressPointCoords = new mapkit.Coordinate(global.pressLati, global.pressLong);
                
                    var pointOption = {
                        data: {fontColor: 'black', color: "black", distance: distance, fontLarger: true, isDot: true}
                    }
                    pointAnnotation = new mapkit.Annotation(pressPointCoords, dotFactory, pointOption);
                    map.addAnnotation(pointAnnotation);
    
                    if(currentLocationAnnotation){
                        map.removeAnnotation(currentLocationAnnotation);
                    }
                    var currentCoords = new mapkit.Coordinate(global.currentLati, global.currentLong);
                    var currentOptions = {
                        data: {
                            color: "blue"
                        }
                    }
                    currentLocationAnnotation = new mapkit.Annotation(currentCoords, currentFactory, currentOptions);
                    map.addAnnotation(currentLocationAnnotation);
    
    
                    if(holeViewAnnotationArray.length > 0){
                        for(let i = 0; i < holeViewAnnotationArray.length; i++){
                            map.removeAnnotation(holeViewAnnotationArray[i]);
                        }
                    }
    
                    holeViewAnnotationArray=[];
    
                    console.log("global.holeviewdata: ", global.holeViewData);
                    for(let i = 0; i < global.holeViewData.length; i++){
                        var item = global.holeViewData[i];
                        var distance = getDistance(global.currentLati, global.currentLong, item.latitude, item.longitude);
                        var options = {
                            
                            data: { fontColor: item.color, color: item.color, distance: Math.round(distance), fontLarger: true, flagUrl:  global.flagName, isDot: false, fontSize: item.fontSize, number: i }
                        };
                        console.log("******************8")
                        console.log(options)
                        acoordinate = new mapkit.Coordinate(item.latitude, item.longitude);
                        annotation = new mapkit.Annotation(acoordinate, factory, options);
                        map.addAnnotation(annotation);
                        holeViewAnnotationArray.push(annotation);
                    }

                    if(edgeAnnotationArray.length > 0){
                        for(let i = 0; i < edgeAnnotationArray.length; i++){
                            map.removeAnnotation(edgeAnnotationArray[i]);
                        }
                    }
    
                    edgeAnnotationArray=[];
    
                    console.log("global.holeEdgeData: ", global.holeEdgeData);
                    for(let i = 0; i < global.holeEdgeData.length; i++){
                        var item = global.holeEdgeData[i];
                        var distance = getDistance(global.currentLati, global.currentLong, item.latitude, item.longitude);
                        var options = {
                            
                            data: { fontColor: item.color, color: item.color, distance: Math.round(distance), fontLarger: true, flagUrl:  global.flagName, isDot: false, fontSize: item.fontSize, number: i }
                        };
                        acoordinate = new mapkit.Coordinate(item.latitude, item.longitude);
                        annotation = new mapkit.Annotation(acoordinate, edgeFactory, options);
                        map.addAnnotation(annotation);
                        edgeAnnotationArray.push(annotation);
                    }
                }


                
            });
      
            const listener = {
                message: envelope => {
                    console.log("pubnub result: ", envelope);
                    const { message, publisher } = envelope;
  
                    const {latitude, longitude } = message;
                
                    
                    setCurrentLati(latitude);
                    setCurrentLong(longitude);
                    if(publisher == global.userName) {
            
                        console.log("message: ", message);

                        global.currentLati = Number(latitude);
                        global.currentLong = Number(longitude);

                        if(pointAnnotation){
                            map.removeAnnotation(pointAnnotation);
                        }
        
                        var distance = ""
        
                        if(global.currentLati != 0 && global.pressLati != 0){
                            distance = Math.round(getDistance(global.currentLati, global.currentLong, global.pressLati, global.pressLong));
        
                            // let toRotation = Math.round(getDistance(global.rotationLati, global.rotationLong, global.pressLati, global.pressLong));
        
                            distance = distance.toString(); 
                        }
        
                        var pressPointCoords = new mapkit.Coordinate(global.pressLati, global.pressLong);
                    
                        var pointOption = {
                            data: {fontColor: 'black', color: "black", distance: distance, fontLarger: true, isDot: true}
                        }
                        pointAnnotation = new mapkit.Annotation(pressPointCoords, dotFactory, pointOption);
                        map.addAnnotation(pointAnnotation);
        
                        if(currentLocationAnnotation){
                            map.removeAnnotation(currentLocationAnnotation);
                        }
                        var currentCoords = new mapkit.Coordinate(global.currentLati, global.currentLong);
                        var currentOptions = {
                            data: {
                                color: "blue"
                            }
                        }
                        currentLocationAnnotation = new mapkit.Annotation(currentCoords, currentFactory, currentOptions);
                        map.addAnnotation(currentLocationAnnotation);
        
        
                        if(holeViewAnnotationArray.length > 0){
                            for(let i = 0; i < holeViewAnnotationArray.length; i++){
                                map.removeAnnotation(holeViewAnnotationArray[i]);
                            }
                        }
        
                        holeViewAnnotationArray=[];
        
                        console.log("global.holeviewdata: ", global.holeViewData);
                        for(let i = 0; i < global.holeViewData.length; i++){
                            var item = global.holeViewData[i];
                            var distance = getDistance(global.currentLati, global.currentLong, item.latitude, item.longitude);
                            var options = {
                                
                                data: { fontColor: item.color, color: item.color, distance: Math.round(distance), fontLarger: true, flagUrl:  global.flagName, isDot: false, fontSize: item.fontSize, number: i }
                            };
                            acoordinate = new mapkit.Coordinate(item.latitude, item.longitude);
                            annotation = new mapkit.Annotation(acoordinate, factory, options);
                            map.addAnnotation(annotation);
                            holeViewAnnotationArray.push(annotation);
                        }

                        if(edgeAnnotationArray.length > 0){
                            for(let i = 0; i < edgeAnnotationArray.length; i++){
                                map.removeAnnotation(edgeAnnotationArray[i]);
                            }
                        }
        
                        edgeAnnotationArray=[];
        
                        console.log("global.holeEdgeData: ", global.holeEdgeData);
                        for(let i = 0; i < global.holeEdgeData.length; i++){
                            var item = global.holeEdgeData[i];
                            var distance = getDistance(global.currentLati, global.currentLong, item.latitude, item.longitude);
                            var options = {
                                
                                data: { fontColor: item.color, color: item.color, distance: Math.round(distance), fontLarger: true, flagUrl:  global.flagName, isDot: false, fontSize: item.fontSize, number: i }
                            };
                            acoordinate = new mapkit.Coordinate(item.latitude, item.longitude);
                            annotation = new mapkit.Annotation(acoordinate, edgeFactory, options);
                            map.addAnnotation(annotation);
                            edgeAnnotationArray.push(annotation);
                        }

                    }


                    
                }
            };

            pubnub.addListener(listener);
    
        } 
        );
    }

    return (
        <div>
            {/* <div>{global.userName}</div> */}
            {/* <div>{mess}</div>
            <div>{currentLati}</div>
            <div>{currentLong}</div> */}
            <div style={{width: "100%", height: windowDimenion.winHeight *  1}} id="map" />
        </div>
        
    )
}

export default AccuRangeMap;


const rotationFactory = (coordinate, options) => {
 
    const { flagUrl } = options.data;

    console.log("flagUrl: ", flagUrl);
    var div = document.createElement("div");
    div.className = "circle-annotation";
    var image = new Image();
    image.src =flagUrl
    image.className = "image-size"
    div.appendChild(image);
  
    return div;
};

const factory = (coordinate, options) => {
 
    const { data} = options;
    const { flagUrl, isDot } = data;
    console.log("flagUrl: ", flagUrl);
    var div = document.createElement("div");

    var style = document.createElement('style');
    style.type = 'text/css';

        // div.className = "circle-annotation";
        var image = new Image();
        image.src =flagUrl
        image.className = "image-size"
        div.appendChild(image);
    


    var distance = div.appendChild(document.createElement("div"));
    distance.textContent = data.distance;

    var textStyle = document.createElement('style');

    textStyle.type = 'text/css';
    // if(data.fontLarger){
        textStyle.innerHTML = '.cssClassLarge' + data.number + '{position: absolute; top: -25px; left: 5px; font-size:' + data.fontSize + 'px; font-family: sans-serif; color: ' + data.color + '; font-weight: 600;}';
        document.getElementsByTagName('head')[0].appendChild(textStyle);
        distance.className = "cssClassLarge" + data.number
    // } else {
    //     textStyle.innerHTML = '.cssClassSmall' + data.fontColor + '{position: absolute; top: -20px; left: -7px; font-size: 13px; font-family: sans-serif; color: ' + data.fontColor + ';}';
    //     document.getElementsByTagName('head')[0].appendChild(textStyle);
    //     distance.className = "cssClassSmall" + data.fontColor
    // }

    return div;
};

const dotFactory = (coordinate, options) => {
 
    const { data} = options;
    const { flagUrl, isDot } = data;
    console.log("flagUrl: ", flagUrl);
    var div = document.createElement("div");

    var style = document.createElement('style');
    style.type = 'text/css';

    if(data.fontLarger){
        style.innerHTML = '.cssClass' + data.color + ' {   width: 6px;   height: 6px; border-radius: 50%; background-color:'+ data.color + ';}';
    } else {
        style.innerHTML = '.cssClass' + data.color + ' {   width: 5px;   height: 5px; border-radius: 50%; background-color:'+ data.color + ';}';
    }

    document.getElementsByTagName('head')[0].appendChild(style);
    div.className = "cssClassDot" + data.color;

    var distance = div.appendChild(document.createElement("div"));
    distance.textContent = data.distance;

    var textStyle = document.createElement('style');
    textStyle.type = 'text/css';
    if(data.fontLarger){
        textStyle.innerHTML = '.cssClassLargeDot' + data.fontColor + '{position: absolute; top: -23px; left: -9px; font-size: 17px; font-family: sans-serif; color: ' + data.fontColor + '; font-weight: 600;}';
        document.getElementsByTagName('head')[0].appendChild(textStyle);
        distance.className = "cssClassLargeDot" + data.fontColor
    } else {
        textStyle.innerHTML = '.cssClassSmallDot' + data.fontColor + '{position: absolute; top: -20px; left: -7px; font-size: 13px; font-family: sans-serif; color: ' + data.fontColor + ';}';
        document.getElementsByTagName('head')[0].appendChild(textStyle);
        distance.className = "cssClassSmallDot" + data.fontColor
    }

    return div;
};

const edgeFactory = (coordinate, options) => {
 
    const { data} = options;
    const { flagUrl, isDot } = data;
    console.log("flagUrl: ", flagUrl);
    var div = document.createElement("div");

    var style = document.createElement('style');
    style.type = 'text/css';

    if(data.fontLarger){
        style.innerHTML = '.cssClassEdge' + data.number + ' {   width: 6px;   height: 6px; border-radius: 50%; background-color:'+ data.color + ';}';
    } else {
        style.innerHTML = '.cssClassEdge' + data.number + ' {   width: 5px;   height: 5px; border-radius: 50%; background-color:'+ data.color + ';}';
    }
    console.log("------------->fontSize: ", data.fontSize);
    console.log("------------->fontColor: ", data.color);
    console.log("------------->fontLarger: ", data.fontLarger);
    document.getElementsByTagName('head')[0].appendChild(style);
    div.className = "cssClassEdge" + data.number;

    var distance = div.appendChild(document.createElement("div"));
    distance.textContent = data.distance;

    var textStyle = document.createElement('style');
    textStyle.type = 'text/css';
    // if(data.fontLarger){
        textStyle.innerHTML = '.cssClassLargeEdge' + data.number + '{position: absolute; top: -23px; left: -9px; font-size:' + data.fontSize +'px; font-family: sans-serif; color: ' + data.color + '; font-weight: 600;}';
        document.getElementsByTagName('head')[0].appendChild(textStyle);
        distance.className = "cssClassLargeEdge" + data.number
    // } else {
    //     textStyle.innerHTML = '.cssClassSmall' + data.fontColor + '{position: absolute; top: -20px; left: -7px; font-size: 13px; font-family: sans-serif; color: ' + data.fontColor + ';}';
    //     document.getElementsByTagName('head')[0].appendChild(textStyle);
    //     distance.className = "cssClassSmall" + data.fontColor
    // }

    return div;
};

const currentFactory = (coordinate, options) => {
 
    const { data} = options;

    var div = document.createElement("div");
    div.className = "currentIndicator"

   
    return div;
};

export function getDistance (

    lati1, long1, lati2, long2

) {

    const step1 = degsToRads(90 - lati2);

    const step2 = Math.cos(step1);

    const step3 = degsToRads(90-lati1);

    const step4 = Math.cos(step3);

    const step5 = step4 * step2;

    const step6 = degsToRads(90 - lati2);

    const step7 = Math.sin(step6);

    const step8 = degsToRads(90 - lati1);

    const step9 = Math.sin(step8);

    const step10 = step9 * step7;

    const step11 = degsToRads(long2 - long1);

    const step12 = Math.cos(step11);

    const step13 = step12 * step10;

    const step14 = step13 + step5;

    const step15 = Math.acos(step14);

    const step16 = step15 * 6371;

    const step17 = step16 / 1.609;

    const step18 = step17 * 1760;



    return step18;

}


export function getHeadingFromUL(currentLati, currentLong, upperLeftLati, upperLeftLong) {



    let step1 = upperLeftLati;

    let step2 = degsToRads(step1);

    let step3 = Math.cos(step2);

    let step4 = currentLati;

    let step5 = degsToRads(step4);

    let step6 = Math.sin(step5);

    let step7 = step3 * step6;

    let step8 = upperLeftLati;

    let step9 = degsToRads(step8);

    let step10 = Math.sin(step9);

    let step11 = currentLati;

    let step12 = degsToRads(step11);

    let step13 = Math.cos(step12);

    let step14 = step10 * step13

    let step15 = currentLong - upperLeftLong;

    let step16 = degsToRads(step15);

    let step17 = Math.cos(step16);

    let step18 = step14 * step17;

    let step19 = step7 - step18;

    let step20 = currentLong - upperLeftLong;

    let step21 = degsToRads(step20);

    let step22 = Math.sin(step21);

    let step23 = currentLati;

    let step24 = degsToRads(step23);

    let step25 = Math.cos(step24);

    let step26 = step22 * step25;

    let step27 = Math.atan2(step26, step19);

    let step28 = radsToDegs(step27);

    let step29 = step28 % 360;



    let heading = step29;



    return heading

  }

  export function degsToRads (deg){

    return (deg * Math.PI) / 180.0;

}



export function radsToDegs (rad){

    return rad * 180 / Math.PI;

}