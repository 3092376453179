import './style.css';
import React, {useState, useEffect, CSSProperties} from 'react';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    Nav,
    Table,
    Container,
    Row,
    Col,
  } from "reactstrap";

import { usePubNub } from 'pubnub-react';
import Chart from "react-apexcharts";
// core components
import BounceLoader from "react-spinners/BounceLoader";
import CustomerHeader from "../../components/Headers/CustomerHeader";

import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';

let basedata = {
    options: {
        chart: {
            id: "basic-bar"
        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#008FFB'
            },
            axisTicks: {
                show: true
            },
            labels: {
                show: true,
                style: {
                    colors: '#008FFB',
                }
            },
            title: {
                text: 'Time',
                style: {
                    color: '#008FFB',
                }
            },
            tooltip: {
                enabled: true
            }

        },
        xaxis: {
            tickPlacement: 'on',
            tickAmount: 15,
            categories: [
                // 'Dec 01','Dec 01','Dec 01','Dec 01'
            ],
            axisBorder: {
                show: true,
                // color: '#008FFB'
            },
            axisTicks: {
                show: true
            },
            labels: {
                show: true,
                rotate: -45,
                style: {
                    // colors: '#008FFB',
                    fontSize: '16px'
                }
            },
            title: {
                // text: 'Time',
                // style: {
                //     color: '#008FFB',
                // }
            },
            tooltip: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
            borderRadius: 4,
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 2],
            dashArray: [1, 5]
        },
        title: {
            text: '',
            align: 'center',
            offsetY: 0
        },
    
        tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if(typeof y !== "undefined") {
                  return  y.toFixed(1) + " mins";
                }
                return y;
              }
            }
          }
    },
   
    series: [

    ]
};



const list = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18
];

const override:CSSProperties  = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};


function  HistoryAnalysis(props){

    const [isRead, setIsRead] = useState(false);

    const [udid, setUdid] = useState("");

    const [filter, setFilter] = useState({
        period: 30,
        holeNum: 1,
        checkHalf: "front" // "back", "turn"
    });

    const [startTime, setStartTime] = useState("");
    const [lastBoxNumber, setLastBoxNumber] = useState("");
    const [cumulatedTime, setCumulatedTime] = useState("");
    const [playedHoleCount, setPlayedHoleCount] = useState("");
    const [timeOnCourse, setTimeOnCourse] = useState("");
    const [playMode, setPlayMode] = useState(1);

    const [holeData, setHoleData] = useState(basedata);
    const [holeChartSeries, setHoleChartSeries] = useState(basedata.series);
    const [holeChartOptions, setHoleChartOptions] = useState(basedata.options);
    const [holeAverageList, setHoleAverageList] = useState([]);

    const [halfChartSeries, setHalfChartSeries] = useState(basedata.series);
    const [halfChartOptions, setHalfChartOptions] = useState(basedata.options);

    const [roundChartSeries, setRoundChartSeries] = useState(basedata.series);
    const [roundChartOptions, setRoundChartOptions] = useState(basedata.options);

    const [recordList, setRecordList] = useState([]);
    const pubnub = usePubNub();

    let [loading, setLoading] = useState(false);

    const [check5, setCheck5] = useState(false);

    const handleClickPeriodFilter = (period) => {
        setFilter({...filter, period: period});
        getReportRound(global.golfer.golfer_id, period);
    }

    function handleHoleCheckboxChange(event) {
        var value = event.target.value;
        setFilter({...filter, holeNum: Number(value) + 1});
        getReportHole(global.golfer.golfer_id, filter.period, Number(value) + 1);
    }

    function handleHalfTypeChange(event) {

        var value = event.target.value;
        setFilter({...filter, checkHalf: value});
        getReportHalfRound(global.golfer.golfer_id, filter.period, value);
    }

    useEffect(() => {
       
        const { state } = props.location;
        // console.log("golfer: ", state.golfer);
        const golfer = state.golfer;
        const name = golfer.name == "" ? golfer.udid : golfer.name;
        setUdid(name);
        global.golfer = golfer;

        if(!isRead){
            setIsRead(true);
            // getReportHole(golfer.golfer_id, filter.period, filter.holeNum);
            getReportRound(golfer.golfer_id, filter.period);
            
        }
       
    }, [])

    const getReportHole = (golferId, period, hole_id) => {
        setLoading(true);
        customerApi.getReportHole({golfer_id: golferId, period: period, hole_id: hole_id})
          .then((response) => {
            // console.log("getReportHole Response: ");
            // console.log(response);
            setLoading(false);
            if(response.status === 200){
            
                const {hole_data, average_list } = response.data;
                const dataList = hole_data.date_list;

                let expected_time = 0;
                for(let i = 0; i < average_list.length; i++){
                    if(hole_id == average_list[i].hole){
                        expected_time = Number(average_list[i].expected_time);
                    }
                }

                let columnData = [];
                let lineData = [];
                let dateData = [];
                let expectData = [];
                for(let i = dataList.length -1; i >= 0; i--){
                    columnData.push(dataList[i].time);
                    dateData.push(dataList[i].date);
                    lineData.push(hole_data.average);
                    expectData.push(expected_time);
                }

                setHoleChartData(columnData, lineData, dateData, expectData, period, hole_id);

                console.log("average list: ", average_list);
                setHoleAverageList(average_list);

                // getReportHalfRound(golferId, period, filter.checkHalf);

            } else {
              helper.showToast("Error", "Failed to get Hole Time List", 3);
            }
          })
          .catch((error) => {
            console.log("err: ", error);
            helper.showToast("Error", "Invaid auth", 3);
          })
    }

    const setHoleChartData = (columnData, lineData, dateData, expectData, period, hole_id) => {
        const series = [
            {
                name: "Hole " + hole_id,
                type: 'column',
                data: columnData
              },
              {
                name: "Average",
                type: 'line',
                data: lineData          
              },
              {
                name: "Expected Time",
                type: 'line',
                data: expectData          
              },
        ];

        const options = {
            chart: {
                id: "basic-bar"
            },
            yaxis: {
                // forceNiceScale: true,
                min: 6,
                max: 20,
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                axisTicks: {
                    show: true
                },
                labels: {
                    show: true,
                    style: {
                        colors: '#008FFB',
                    }
                },
                title: {
                    text: 'Time',
                    style: {
                        color: '#008FFB',
                    }
                },
                // tooltip: {
                //     enabled: true
                // }
            },
            xaxis: {
                tickPlacement: 'on',
                tickAmount: 15,
                categories: dateData,
                axisBorder: {
                    show: true,
                    // color: '#008FFB'
                },
                axisTicks: {
                    show: true
                },
                labels: {
                    show: true,
                    rotate: -45,
                    style: {
                        // colors: '#008FFB',
                        fontSize: '16px'
                    }
                },
                title: {
                    // text: 'Time',
                    // style: {
                    //     color: '#008FFB',
                    // }
                },
                tooltip: {
                    enabled: true
                }
            },
            plotOptions: {
                bar: {
                borderRadius: 4,
                //   horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [1, 2, 2],
                dashArray: [1, 5]
            },
            colors: ['#2E93fA', '#66DA26', '#546E7A',],
            title: {
                text: period + ' Days Report for Hole ' + hole_id + " (Average Time:  " + lineData[0] +"mins, Expected Time: " + expectData[0]  + "mins)",
                align: 'center',
                offsetY: 0
            },

            tooltip: {
                // shared: true,
                // intersect: false,
                // y: {
                //   formatter: function (y) {
                //     if(typeof y !== "undefined") {
                //       return  y.toFixed(1) + " mins";
                //     }
                //     return y;
                //   }
                // }

                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return '<div class="arrow_box" style={{padding: 10px}}>' +
                      '<span>' + series[0][dataPointIndex] + " mins" + '</span>' +
                      '</div>'
                }
            }
        };

        // console.log("options: ", options);
        // console.log("series: ", series);


        setHoleChartSeries(series);
        setHoleChartOptions(options);
    }

    const getReportHalfRound = (golferId, period, checkHalf) => {
        var type = 1;
        if(checkHalf == 'front'){
            type = 1;
        } else if(checkHalf == 'back'){
            type = 2;
        } else {
            type = 3;
        }
        customerApi.getReportHalfRound({golfer_id: golferId, period: period, type: type})
          .then((response) => {
            console.log("getReportHole Response: ");
            console.log(response);
    
            if(response.status === 200){
            
                const {half_round_data } = response.data;
                const dataList = half_round_data.date_list;

                let columnData = [];
                let lineData = [];
                let dateData = [];
                let expectData = [];
                for(let i = dataList.length -1; i >= 0; i--){
                    columnData.push(dataList[i].time);
                    dateData.push(dataList[i].date);
                    lineData.push(half_round_data.average);
                    expectData.push(half_round_data.expected_time);
                }

                setHalfChartData(columnData, lineData, dateData,expectData, period, checkHalf);
                // getReportRound(golferId, period);
                getReportHole(golferId, period, filter.holeNum);

            } else {
              helper.showToast("Error", "Failed to get Hole Time List", 3);
            }
          })
          .catch((error) => {
            console.log("err: ", error);
            helper.showToast("Error", "Invaid auth", 3);
          })
    }

    const setHalfChartData = (columnData, lineData, dateData, expectData, period, checkHalf) => {
        const series = [
            {
                name: checkHalf[0].toUpperCase() + checkHalf.substring(1) + ( checkHalf == "turn" ? "" : " 9"),
                type: 'column',
                data: columnData
              },
              {
                name: "Average",
                type: 'line',
                data: lineData          
              },
              {
                name: "Expected Time",
                type: 'line',
                data: expectData          
              },
        ];

        const options = {
            chart: {
                id: "basic-bar"
            },
            yaxis: {
                min: checkHalf == "front" | checkHalf == "back" ? 60 : 0,
                max: checkHalf == "front" | checkHalf == "back" ? 150 : 15,
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                axisTicks: {
                    show: true
                },
                labels: {
                    show: true,
                    style: {
                        colors: '#008FFB',
                    }
                },
                title: {
                    text: 'Time',
                    style: {
                        color: '#008FFB',
                    }
                },
                // tooltip: {
                //     enabled: true
                // }
            },
            xaxis: {
                tickPlacement: 'on',
                tickAmount: 15,
                categories: dateData,
                axisBorder: {
                    show: true,
                    // color: '#008FFB'
                },
                axisTicks: {
                    show: true
                },
                labels: {
                    show: true,
                    rotate: -45,
                    style: {
                        // colors: '#008FFB',
                        fontSize: '16px'
                    }
                },
                title: {
                    // text: 'Time',
                    // style: {
                    //     color: '#008FFB',
                    // }
                },
                tooltip: {
                    enabled: true
                }
            },
            plotOptions: {
                bar: {
                borderRadius: 4,
                //   horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [1, 2, 2],
                dashArray: [1, 5]
            },
            colors: ['#2E93fA', '#66DA26', '#546E7A',],
            title: {
                text: period + ' Days Report for ' + (checkHalf[0].toUpperCase() + checkHalf.substring(1) + ( checkHalf == "turn" ? "" : " 9")) + " (Average Time: " + lineData[0] +"mins, Expected Time: " + expectData[0]  + "mins)",
                align: 'center',
                offsetY: 0
            },

            tooltip: {
                shared: true,
                intersect: false,
                // y: {
                //   formatter: function (y) {
                //     if(typeof y !== "undefined") {
                //       return  y.toFixed(1) + " mins";
                //     }
                //     return y;
                //   }
                // }
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return '<div class="arrow_box" style={{padding: 10px}}>' +
                      '<span>' + series[0][dataPointIndex] + " mins" + '</span>' +
                      '</div>'
                }
            }
        };

        // console.log("options: ", options);
        // console.log("series: ", series);

        setHalfChartSeries(series);
        setHalfChartOptions(options);
    }

    const getReportRound = (golferId, period) => {
        customerApi.getReportRound({golfer_id: golferId, period: period})
          .then((response) => {
            // console.log("getReportHole Response: ");
            // console.log(response);
    
            if(response.status === 200){
            
                const {round_data } = response.data;
                const dataList = round_data.date_list;

                let columnData = [];
                let lineData = [];
                let dateData = [];
                let expectData = [];
                for(let i = dataList.length -1; i >= 0; i--){
                    columnData.push(dataList[i].time);
                    dateData.push(dataList[i].date);
                    lineData.push(round_data.average);
                    expectData.push(round_data.expected_time);
                }

                setRoundChartData(columnData, lineData, dateData, expectData, period);
                getReportHalfRound(golferId, period, filter.checkHalf);

            } else {
              helper.showToast("Error", "Failed to get Hole Time List", 3);
            }
          })
          .catch((error) => {
            console.log("err: ", error);
            helper.showToast("Error", "Invaid auth", 3);
          })
    }

    const setRoundChartData = (columnData, lineData, dateData, expectData, period) => {
        const series = [
            {
                name: "Total",
                type: 'column',
                data: columnData
              },
              {
                name: "Average",
                type: 'line',
                data: lineData          
              },
              {
                name: "Expected Time",
                type: 'line',
                data: expectData          
              },
        ];

        const options = {
            chart: {
                id: "basic-bar"
            },
            yaxis: {
                min: 120,
                max: 300,
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                axisTicks: {
                    show: true
                },
                labels: {
                    show: true,
                    style: {
                        colors: '#008FFB',
                    }
                },
                title: {
                    text: 'Time',
                    style: {
                        color: '#008FFB',
                    }
                },
                // tooltip: {
                //     enabled: true
                // }
            },
            xaxis: {
                tickPlacement: 'on',
                tickAmount: 15,
                categories: dateData,
                axisBorder: {
                    show: true,
                    // color: '#008FFB'
                },
                axisTicks: {
                    show: true
                },
                labels: {
                    show: true,
                    rotate: -45,
                    style: {
                        // colors: '#008FFB',
                        fontSize: '16px'
                    }
                },
                title: {
                    // text: 'Pace',
                    // style: {
                    //     color: '#008FFB',
                    // }
                },
                tooltip: {
                    enabled: true
                }
            },
            plotOptions: {
                bar: {
                borderRadius: 4,
                //   horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [1, 2, 2],
                dashArray: [1, 5]
            },
            colors: ['#2E93fA', '#66DA26', '#546E7A',],
            title: {
                text: period + " Days Report (Average Time: " + lineData[0] +"mins, Expected Time: " + expectData[0]  + "mins)",
                align: 'center',
                offsetY: 0
            },

            tooltip: {
                shared: true,
                intersect: false,
                // y: {
                //   formatter: function (y) {
                //     if(typeof y !== "undefined") {
                //       return  y.toFixed(1) + " mins";
                //     }
                //     return y;
                //   }
                // }
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return '<div class="arrow_box" style={{padding: 10px}}>' +
                      '<span>' + series[0][dataPointIndex] + " mins" + '</span>' +
                      '</div>'
                }
            }
        };

        // console.log("options: ", options);
        // console.log("series: ", series);

        setRoundChartSeries(series);
        setRoundChartOptions(options);
    }


    return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--5" fluid >
         <Row>
         <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <BounceLoader color="#36d7b7" loading={loading} cssOverride={override} size={40} />
                
                <Row className="align-items-center">

                    <div className="col text-right">
                        <h3 className="text-success mb-0"></h3>
                    </div>
               
                    <div className="col">
                        <h2 className="text-white mb-0 text-center">Status</h2>
                    </div>


                    <div className="col text-right">
                    <Button
                            color={filter.period === 30 ? "success" : "info"}
                    
                            onClick={(e) => handleClickPeriodFilter(30)}
                        
                            size="sm"
                            // id={index}
                        >
                        
                            30 Days
                        </Button>
                        <Button
                            color={filter.period === 90 ? "success" : "info"}
                    
                            onClick={(e) => handleClickPeriodFilter(90)}
                        
                            size="sm"
                            // id={index}
                        >
                        
                            90 Days
                        </Button>
                        <Button
                            color={filter.period === 365 ? "success" : "info"}
                    
                            onClick={(e) => handleClickPeriodFilter(365)}
                        
                            size="sm"
                            // id={index}
                        >
                        
                            Cal Year
                        </Button>
                    </div>
                
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                {/* <thead className="thead-dark">
                  <tr>
                 
                    <th scope="col">ID</th>
                  
                    <th scope="col">
                      
                            Start
                        
                            Time
                       
                    </th>
                    <th scope="col">
                        <div className="row">
                            Last Hole
                        </div>
                        <div className="row">
                            Played
                        </div>
                    </th>
                    <th scope="col">
                        <div className="row">
                            # of Holes
                        </div>
                        <div className="row">
                            Played
                        </div>
                    </th>
                    <th scope="col">
                        <div className="row">
                            Cumulative
                        </div>
                        <div className="row">
                            pace
                        </div>
                    </th>

                    <th scope="col">
                        <div className="row">
                            Time on
                        </div>
                        <div className="row">
                            Course
                        </div>
                    </th>
                 
                    
                  
                  
                  </tr>
                </thead> */}
                {/* <tbody>

                  <tr>
                    <th  scope="row">
                    {udid}
                    </th>
                  
  
                    <td>{startTime}</td>
                  
                    <td>
                        {lastBoxNumber}
                    </td>
                    <td>
                        {playedHoleCount}
                    </td>
                
                    <td>
                    {timeOnCourse}
                    </td>
                    <td className=" align-items-center">
                        
                    
                    </td>
                 
                  </tr>


                </tbody> */}
              </Table>
            </Card>
          </Col>

         

        </Row>
     
        </Container>

        <Container className="mt-3" fluid >

            <Card className="bg-white shadow">
                <CardHeader className="bg-transparent border-0">
                    
                    <Row className="align-items-center">

                    <div className="col text-right">
                            <h3 className="text-success mb-0"></h3>
                        </div>
                
                        <div className="col">
                            <h2 className="text-white mb-0 text-center">Status</h2>
                        </div>


                        <div className="col text-right">
                            <h3 className="text-success mb-0"></h3>
                        </div>
                    
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="mixed-chart">
                        <Chart
                            options={roundChartOptions}
                            series={roundChartSeries}
                            type="bar"
                            width="100%"
                            height="550"
                        />
                    </div>
           
                </CardBody>
            </Card>

            

            <Card className="bg-white shadow">
                <CardHeader className="bg-transparent border-0">
                    
                    <Row className="align-items-center">

                    <div className="col text-right">
                            <h3 className="text-success mb-0"></h3>
                        </div>
                
                        <div className="col">
                            <h2 className="text-white mb-0 text-center">Status</h2>
                        </div>


                        <div className="col text-right">
                            <h3 className="text-success mb-0"></h3>
                        </div>
                    
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="mixed-chart">
                        <Chart
                            options={halfChartOptions}
                            series={halfChartSeries}
                            type="bar"
                            width="100%"
                            height="550"
                        />
                    </div>
                    <Row className="mb-2 mb-xl-0" xl="6">
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <input 
                                
                                checked={ filter.checkHalf == "front" ? true : false } 
                
                                type="radio" 
                                style={{marginLeft: 10, marginTop: -5, marginRight: 10}}  
                                onChange={handleHalfTypeChange} 
                                value="front"
                            />
                            <label >
                                Front 9
                            </label>

                            <input 
                                
                                checked={ filter.checkHalf == "back" ? true : false } 
                
                                type="radio" 
                                style={{marginLeft: 10, marginTop: -5, marginRight: 10}}  
                                onChange={handleHalfTypeChange} 
                                value="back"
                            />
                            <label >
                                Back 9
                            </label>

                            <input 
                                
                                checked={ filter.checkHalf == "turn" ? true : false } 
                
                                type="radio" 
                                style={{marginLeft: 10, marginTop: -5, marginRight: 10}}  
                                onChange={handleHalfTypeChange} 
                                value="turn"
                            />
                            <label >
                                Turn
                            </label>
                        
                        </div>
                    </Row>
                </CardBody>
            </Card>

            <Card className="bg-white shadow">
                <CardHeader className="bg-transparent border-0">
                    
                    <Row className="align-items-center">

                    <div className="col text-right">
                            <h3 className="text-success mb-0"></h3>
                        </div>
                
                        <div className="col">
                            <h2 className="text-white mb-0 text-center">Status</h2>
                        </div>


                        <div className="col text-right">
                            <h3 className="text-success mb-0"></h3>
                        </div>
                    
                    </Row>
                </CardHeader>
                <CardBody>
                    {/* <Col className="mb-5 mb-xl-0" xl="5"> */}
                        <div className="mixed-chart">
                            <Chart
                                options={holeChartOptions}
                                series={holeChartSeries}
                                type="bar"
                                width="100%"
                                height="550"
                            />
                        </div>
                    {/* </Col> */}
                    
                    <Row className="mb-2 mb-xl-0" xl="6">
                       {
                        holeAverageList  ?
                            holeAverageList.map((item, index) => 
                                <div key={index} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <input 
                                        key={index} 
                                        checked={filter.holeNum == (index + 1) ? true : false} 
                                        id="customCheck1" 
                                        type="radio" 
                                        style={{marginLeft: 10, marginTop: -5, marginRight: 10}}  
                                        onChange={handleHoleCheckboxChange} 
                                        value={index}
                                    />
                                    <label >
                                        Hole {index + 1}
                                    </label>
                                    <label style={{color: "green"}}>
                                        :  {item.average} min
                                    </label>
                                </div>
                            )
                          
                            :
                            <></>
                        }
                   
                    </Row>
                </CardBody>
            </Card>
        </Container>
    </>
  );
};

export default HistoryAnalysis;