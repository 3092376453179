import React, {useState, useEffect, useCallback} from 'react';
// node.js library that concatenates classes (strings)

// reactstrap components
import {

  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,

} from "reactstrap";

import { useHistory } from 'react-router-dom';
import { usePubNub } from 'pubnub-react';
import session from '../../services/session.service';
import CustomerHeader from "../../components/Headers/CustomerHeader";


import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';

function  DisplayStatus(props){

    const [clubId, setClubId] = useState(session.get('clubInfo').clubId);
	const [clubName, setClubName] = useState(session.get('clubInfo').clubName);
    const [existTurn, setExistTurn] = useState(session.get('clubInfo').existTurn);

    const [isRead, setIsRead] = useState(false);

    const [udid, setUdid] = useState("");

    const [startTime, setStartTime] = useState("");
    const [lastBoxNumber, setLastBoxNumber] = useState("");
    const [cumulatedTime, setCumulatedTime] = useState("");
    const [playedHoleCount, setPlayedHoleCount] = useState("");
    const [timeOnCourse, setTimeOnCourse] = useState("");
    const [playMode, setPlayMode] = useState(1);

    const [recordList, setRecordList] = useState([]);
    const pubnub = usePubNub();

    useEffect(() => {
        console.log(existTurn);

		// We need to make sure that PubNub is defined

		if (pubnub) {
		  	// Set the UUID of our user

            const { state } = props.location;
            const golfer = state.golfer;
            const prevDay = state.prevDay;
		  	// pubnub.setUUID(golfer.udid);

			pubnub.subscribe({ channels: ["refresh"] });

            const listener = {
                message: envelope => {
                    
                    const { message, publisher } = envelope;
                    console.log("pubnub message: ", message, publisher);
                    if(publisher == golfer.golfer_id){
                        if(prevDay > 0){
                            getPrevGolferStatus(golfer.golfer_id, prevDay, golfer.desc)
                        } else {
                            getGolferStatus(golfer.golfer_id, golfer.desc);
                        }
                    }
                    
                }
            }

            pubnub.addListener(listener);

			return () => {
				pubnub.unsubscribeAll();
			};

		};

	}, [pubnub]);

    useEffect(() => {
       
        const { state } = props.location;
        console.log("golfer: ", state.golfer);
        const golfer = state.golfer;
        const prevDay = state.prevDay;
        console.log("prevDay: ", state.prevDay);
        setUdid(golfer.udid);

        if(!isRead){
            setIsRead(true);
            if(prevDay > 0){
                getPrevGolferStatus(golfer.golfer_id, prevDay, golfer.desc)
            } else {
                getGolferStatus(golfer.golfer_id, golfer.desc);
            }
            

        }
       
    })

    const getGolferStatus = (golferId, desc) => {
        customerApi.getIndividualStatus({golfer_id: golferId, desc: desc})
            .then((response) => {
                console.log("golferId Response: ");
                console.log(response);
        
                if(response.status === 200){
                
                    const {summary, progress} = response.data;

                    setStartTime(summary.start_time);
                
                    setCumulatedTime(summary.cumulated_time);
                    setPlayMode(summary.play_mode);
                    setPlayedHoleCount(summary.played_hole_count);
                    setTimeOnCourse(summary.time_on_course);
                    
                    const lastBoxNumber = summary.last_box_number;
            
                    setLastBoxNumber(lastBoxNumber);

                    setRecordList(progress);
            
                } else {
                    helper.showToast("Error", "Failed to get Hole Time List", 3);
                }
            })
            .catch((error) => {
                console.log("err: ", error);
                helper.showToast("Error", "Invaid auth", 3);
            })
    }

    const getPrevGolferStatus = (golferId, prevDay, desc) => {
        customerApi.getPrevIndividualStatus({golfer_id: golferId, prev_day: prevDay, desc: desc})
            .then((response) => {
                console.log("golferId Response: ");
                console.log(response);
        
                if(response.status === 200){
                
                    const {summary, progress} = response.data;

                    setStartTime(summary.start_time);
                
                    setCumulatedTime(summary.cumulated_time);
                    setPlayMode(summary.play_mode);
                    setPlayedHoleCount(summary.played_hole_count);
                    setTimeOnCourse(summary.time_on_course);
                    
                    const lastBoxNumber = summary.last_box_number;
            
                    setLastBoxNumber(lastBoxNumber);

                    setRecordList(progress);
            
                } else {
                    helper.showToast("Error", "Failed to get Hole Time List", 3);
                }
            })
            .catch((error) => {
                console.log("err: ", error);
                helper.showToast("Error", "Invaid auth", 3);
            })
    }

    return (
        <>
        <CustomerHeader />
        {/* Page content */}
        <Container className="mt--7" fluid >
            <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                    <Card className="bg-default shadow">
                        <CardHeader className="bg-transparent border-0">
                            
                            <Row className="align-items-center">

                                <div className="col text-right">
                                    <h3 className="text-success mb-0"></h3>
                                </div>
                        
                                <div className="col">
                                    <h2 className="text-white mb-0 text-center">Status</h2>
                                </div>


                                <div className="col text-right">
                                    <h3 className="text-success mb-0"></h3>
                                </div>
                            
                            </Row>
                        </CardHeader>
                        <Table
                            className="align-items-center table-dark table-flush"
                            responsive
                        >
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">ID</th>

                                    <th scope="col">
                                    
                                            Start
                                        
                                            Time
                                    
                                    </th>
                                    <th scope="col">
                                        <div className="row">
                                            Last Hole
                                        </div>
                                        <div className="row">
                                            Played
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row">
                                            # of Holes
                                        </div>
                                        <div className="row">
                                            Played
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row">
                                            Cumulative
                                        </div>
                                        <div className="row">
                                            pace
                                        </div>
                                    </th>

                                    <th scope="col">
                                        <div className="row">
                                            Time on
                                        </div>
                                        <div className="row">
                                            Course
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th  scope="row">
                                        {udid}
                                    </th>

                                    <td>{startTime}</td>
                                
                                    <td>
                                        {lastBoxNumber}
                                    </td>
                                    <td>
                                        {playedHoleCount}
                                    </td>
                                    <td>
                                        {
                                            cumulatedTime > 0 ?
                                                <p className="mt-3 mb-0 text-muted text-sm">
                                                <span className="text-success mr-2">
                                                    <i className="fa fa-arrow-up" /> +{cumulatedTime}
                                                </span>
                                        
                                                </p>
                                                :
                                                <></>
                                        }

                                        {
                                            cumulatedTime ==0?
                                                <p className="mt-3 mb-0 text-muted text-sm">
                                                <span className="text-info mr-2">
                                                    <i className="fa fa-arrows-alt-h" /> 0</span> 
                                                
                                        
                                                </p>
                                                :
                                                <></>
                                        }

                                        {
                                            cumulatedTime < 0?
                                                <p className="mt-3 mb-0 text-muted text-sm">
                                                <span className="text-warning mr-2">
                                                    <i className="fa fa-arrow-down" /> {cumulatedTime}
                                                
                                                </span>
                                        
                                                </p>
                                                :
                                                <></>
                                        }
                                            
                                    </td>
                                    <td>
                                        {timeOnCourse}
                                    </td>
                                
                                </tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
        
        </Container>



        <Container className="mt-3" fluid >

            {

                <Row>
                    <Col className="mb-5 mb-xl-0" xl="6">
                        <Card className="bg-default shadow">
                            <CardHeader className="bg-transparent border-0">
                                
                                <Row className="align-items-center">
                                    <div className="col">
                                    
                                    </div>

                                    <div className="col">
                                        <h2 className="text-white mb-0 text-center">
                                            {

                                                playMode === "110" ? "Back 9" : "Front 9" 
                                            }
                                        </h2>
                                    </div>


                                    <div className="col text-right">
                                        
                                    </div>
                                
                                </Row>
                            </CardHeader>
                            <Table
                                className="align-items-center table-dark table-flush"
                                responsive
                            >
                                <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Post-Hole</th>
                                    <th scope="col">Quota</th>
                                    <th scope="col">
                                        <div className="row">
                                            Time
                                        </div>
                                        <div className="row">
                                            Stamp
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row">
                                            Elapsed
                                        </div>
                                        <div className="row">
                                            Time
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row">
                                            Elapsed
                                        </div>
                                        <div className="row">
                                            vs Quota
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row">
                                            Cumulative
                                        </div>
                                        <div className="row">
                                            pace
                                        </div>
                                    </th>

                                    <th scope="col">
                                        <div className="row">
                                            Time Thru
                                        </div>
                                        <div className="row">
                                            Last Hole
                                        </div>
                                    </th>

                                    


                                </tr>
                                </thead>
                                <tbody>

                            
                                {
                                    recordList.length > 0 ?
                                        recordList.map((item, index) => 
                                            item.box_number == 10 && !existTurn ?
                                            <></>
                                            :
                                            index < 10 ?
                                            <tr>
                                                <th  scope="row">
                                           
                                                {Number(item.box_number) == 10 ? "Turn" : Number(item.box_number) > 10 ? Number(item.box_number) - 1 : item.box_number}
                                                </th>
                                                <td>
                                                {item.post_time == null ? "" : item.expect_time }
                                                </td>
                                            
                                                <td>{item.post_time}</td>
                                                <td>{item.elapsed_time}</td>
                                            
                                                <td>
                                                    {
                                                        Number(item.delayed_time) > 0 ?
                                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                                <span className="text-success mr-2">
                                                                    + {item.delayed_time}
                                                                </span>{" "}
                                                            </p>
                                                            :
                                                            <></>
                                                    }

                                                    {
                                                        Number(item.delayed_time) == 0 ?
                                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                                <span className="text-success mr-2">
                                                                0
                                                                </span>{" "}
                                                            </p>
                                                            :
                                                            <></>
                                                    }


                                                    {
                                                        Number(item.delayed_time) < 0?
                                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                            
                                                                <span className="text-warning mr-2">
                                                                    {item.delayed_time }
                                                                </span>{" "}
                                                            </p>
                                                            :
                                                            <></>
                                                    }
                                                    
                                                </td>
                                                <td>
                                                    {
                                                        Number(item.cumulated_time) > 0 ?
                                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                                <span className="text-success mr-2">
                                                                {item.cumulated_time == "" ? "" : "+"} {item.cumulated_time}
                                                                </span>{" "}
                                                            </p>
                                                            :
                                                            <></>
                                                    }

                                                    {
                                                        Number(item.cumulated_time) == 0 ?
                                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                                <span className="text-success mr-2">
                                                                {item.post_time == null ? "" : "0" }
                                                                </span>{" "}
                                                            </p>
                                                            :
                                                            <></>
                                                    }

                                                

                                                    {
                                                        Number(item.cumulated_time) < 0?
                                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                            
                                                                <span className="text-warning mr-2">
                                                                    {item.cumulated_time }
                                                                </span>{" "}
                                                            </p>
                                                            :
                                                            <></>
                                                    }
                                                </td>

                                                <td>{item.time_on_course}</td>

                                                    
                                            
                                            </tr>
                                            :
                                            <></>
                    
                                        )
                                        :
                                        <></>
                                }
                                
                            
                                </tbody>
                            </Table>
                        </Card>
                    </Col>

                    <Col className="mb-5 mb-xl-0" xl="6">
                        <Card className="bg-default shadow">
                            <CardHeader className="bg-transparent border-0">
                                
                                <Row className="align-items-center">
                                
                                    <div className="col">
                                    
                                    </div>

                                    <div className="col">
                                        <h2 className="text-white mb-0 text-center">
                                            {
                                                playMode === "110" ? "Front 9" : "Back 9" 
                                            }
                                    
                                        </h2>
                                    </div>

                                    <div className="col text-right">
                                    
                                    </div>
                                    
                                </Row>
                            </CardHeader>
                            <Table
                                className="align-items-center table-dark table-flush"
                                responsive
                            >
                                <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Post-Hole</th>
                                    <th scope="col">Quota</th>
                                    <th scope="col">
                                        <div className="row">
                                            Time
                                        </div>
                                        <div className="row">
                                            Stamp
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row">
                                            Elapsed
                                        </div>
                                        <div className="row">
                                            Time
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row">
                                            Elapsed
                                        </div>
                                        <div className="row">
                                            vs Quota
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row">
                                            Cumulative
                                        </div>
                                        <div className="row">
                                            pace
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="row">
                                            Time on
                                        </div>
                                        <div className="row">
                                            Course
                                        </div>
                                    </th>
                        
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    recordList.length > 0 ?
                                        recordList.map((item, index) => 
                                            item.box_number == 10 && !existTurn ?
                                            <></>
                                            :
                                            index > 9 && index < 19?
                                            <tr>
                                                <th  scope="row">
                                                {Number(item.box_number) == 10 ? "Turn" : Number(item.box_number) > 10 ? Number(item.box_number) - 1 : item.box_number}
                                                </th>
                                                <td>
                                                    {item.post_time == null ? "" : item.expect_time }
                                                </td>
                                            
                                                <td>{item.post_time}</td>
                                                <td>{item.elapsed_time}</td>
                                            
                                                <td>
                                                {
                                                    Number(item.delayed_time) > 0 ?
                                                        <p className="mt-3 mb-0 text-muted text-sm">
                                                            <span className="text-success mr-2">
                                                                + {item.delayed_time}
                                                            </span>{" "}
                                                        </p>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    Number(item.delayed_time) == 0 ?
                                                        <p className="mt-3 mb-0 text-muted text-sm">
                                                            <span className="text-success mr-2">
                                                            0
                                                            </span>{" "}
                                                        </p>
                                                        :
                                                        <></>
                                                }


                                                {
                                                    Number(item.delayed_time) < 0?
                                                        <p className="mt-3 mb-0 text-muted text-sm">
                                                        
                                                            <span className="text-warning mr-2">
                                                                {item.delayed_time }
                                                            </span>{" "}
                                                        </p>
                                                        :
                                                        <></>
                                                }
                                                    
                                                </td>
                                                <td>
                                                {
                                                    Number(item.cumulated_time) >0 ?
                                                        <p className="mt-3 mb-0 text-muted text-sm">
                                                            <span className="text-success mr-2">
                                                                {item.cumulated_time == "" ? "" : "+"} {item.cumulated_time}
                                                            </span>{" "}
                                                        </p>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    Number(item.cumulated_time) == 0 ?
                                                        <p className="mt-3 mb-0 text-muted text-sm">
                                                            <span className="text-success mr-2">
                                                            {item.post_time == null ? "" : "0" }
                                                            </span>{" "}
                                                        </p>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    Number(item.cumulated_time) <0 ?
                                                        <p className="mt-3 mb-0 text-muted text-sm">
                                                        
                                                            <span className="text-warning mr-2">
                                                                {item.cumulated_time }
                                                            </span>{" "}
                                                        </p>
                                                        :
                                                        <></>
                                                }
                                                </td>

                                                <td>{item.time_on_course}</td>

                                            </tr>
                                            :
                                            <></>
                    
                                        )
                                        :
                                        <></>
                                }
                            
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
        
            }
            
     
        </Container>
    </>
    );
};

export default DisplayStatus;