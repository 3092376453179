import { useState, useEffect, useRef } from "react";
// node.js library that concatenates classes (strings)

// reactstrap components
import {
	Card,
	CardHeader,
	Table,
	Row,
	Button,
	Modal
} from "reactstrap";

import { usePubNub } from 'pubnub-react';

import { useHistory } from 'react-router-dom';
import CustomerHeader from "../../components/Headers/CustomerHeader";
import adminApi from '../../services/admin.service';
import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

import './summary.scss';
global.sortMode = "byTime";
let isBtnPress0 = true;

function  AdminSummary(props){
	const history = useHistory();

	let count = 0;

	const [isRead, setIsRead] = useState(false);
	const [golferList, setGolferList] = useState([]);

	const [golferCount, setGolferCount] = useState(0);

	const [btnText1, setBtnText1] = useState("");
	const [btnText2, setBtnText2] = useState("");
	const [btnText3, setBtnText3] = useState("");
	const [btnText4, setBtnText4] = useState("");
	const [btnText0, setBtnText0] = useState("");

	const [btnPress0, setBtnPress0] = useState(true);
	const [btnPress1, setBtnPress1] = useState(false);
	const [btnPress2, setBtnPress2] = useState(false);
	const [btnPress3, setBtnPress3] = useState(false);
	const [btnPress4, setBtnPress4] = useState(false);

	const [prevDay, setPrevDay] = useState(0);

	const [alertShow, setAlertShow] = useState(false);
	const pubnub = usePubNub();

	const onGetToday = () => {
		getGolferList();
		setBtnPress0(true);
		isBtnPress0 = true;
		setBtnPress1(false);
		setBtnPress2(false);
		setBtnPress3(false);
		setBtnPress4(false);
		getPrevGolferList(0);
		setPrevDay(0);
	}

	const onGetPrevDay1 = () => {
		setBtnPress0(false);
		isBtnPress0 = false;
		setBtnPress1(true);
		setBtnPress2(false);
		setBtnPress3(false);
		setBtnPress4(false);
		getPrevGolferList(1);
		setPrevDay(1);
	}

	const onGetPrevDay2 = () => {
		setBtnPress0(false);
		isBtnPress0 = false;
		setBtnPress1(false);
		setBtnPress2(true);
		setBtnPress3(false);
		setBtnPress4(false);
		getPrevGolferList(2);
		setPrevDay(2);
	}

	const onGetPrevDay3 = () => {
		setBtnPress0(false);
		isBtnPress0 = false;
		setBtnPress1(false);
		setBtnPress2(false);
		setBtnPress3(true);
		setBtnPress4(false);
		getPrevGolferList(3);
		setPrevDay(3);
	}

	const onGetPrevDay4 = () => {
		setBtnPress0(false);
		isBtnPress0 = false;
		setBtnPress1(false);
		setBtnPress2(false);
		setBtnPress3(false);
		setBtnPress4(true);
		getPrevGolferList(4);
		setPrevDay(4);
	}

	const getPrevGolferList = (prevDay) => {
		console.log("prev day: ", prevDay);

		adminApi.getAllPrevGolferList({prev_day: prevDay })
			.then((response) => {
				console.log("getGolferList Response: ");
				console.log(response);

				if(response.status === 200){

				let tempGolferList = response.data;
				global.golferList = tempGolferList;
			
				setGolferCount(tempGolferList.length);

				if(global.sortMode === "byTime"){
					sortByTime(tempGolferList);
				}else if(global.sortMode === "byTimeDecrease"){
					sortByTimeDecrease(tempGolferList);
				
				}	else if(global.sortMode === "byLastHole"){
					sortByLastHole(tempGolferList);
				
				}else if(global.sortMode === "byLastHoleDecrease"){
					sortByLastHoleDecrease(tempGolferList);
				} else if(global.sortMode === "byCount"){
					sortByCount(tempGolferList);
				} else if(global.sortMode === "byCountDecrease"){
					sortByCountDecrease(tempGolferList);
				}else if(global.sortMode === "byPaceDecrease"){
					sortByPaceDecrease(tempGolferList);
				} else {
					// byPace
					sortByPace(tempGolferList);
				}
		
		
				} else {
					helper.showToast("Error", "Failed to get Hole Time List", 3);
				}
			})
			.catch((error) => {
					console.log("err: ", error);
					helper.showToast("Error", "Invaid auth", 3);
			})
	}

	useEffect(() => {
			
		// console.log("clubId: ", clubId);
		// console.log("ClubName: ", clubName);

		if(!isRead){
			setBtnText0(getPrevDay(0));
			setBtnText1(getPrevDay(1));
			setBtnText2(getPrevDay(2));
			setBtnText3(getPrevDay(3));
			setBtnText4(getPrevDay(4));
			setIsRead(true);
			getGolferList();
		}

	});

	useEffect(() => {
		const interval = setInterval(() => {
			processGolferList();
			count ++;
			if(count == 10){
				count = 0;
				if(isBtnPress0){
					getGolferList();
				}
				
			}
			
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	const processGolferList = () => {
		if(global.sortMode === "byClub"){
			sortByClubAlt(global.golferList);
		}else if(global.sortMode === "byClubDecrease"){
			sortByClubDecreaseAlt(global.golferList);
		} else if(global.sortMode === "byTime"){
			sortByTimeAlt(global.golferList);
		}else if(global.sortMode === "byTimeDecrease"){
			sortByTimeDecreaseAlt(global.golferList);
		}else if(global.sortMode === "byLastHole"){
			sortByLastHoleAlt(global.golferList);
		}else if(global.sortMode === "byLastHoleDecrease"){
			sortByLastHoleDecreaseAlt(global.golferList);
		} else if(global.sortMode === "byCount"){
			sortByCountAlt(global.golferList);
		} else if(global.sortMode === "byCountDecrease"){
			sortByCountDecreaseAlt(global.golferList);
		}else if(global.sortMode === "byPaceDecrease"){
			sortByPaceDecreaseAlt(global.golferList);
		} else {
			// byPace
			sortByPaceAlt(global.golferList);
		}

		// console.log("new golferList: ", global.golferList);
		// console.log("golferList: ", global.oldGolferList);

		let tempArray = [];
		for(let i = 0; i < global.golferList.length; i++){

			let tempItem = global.golferList[i];

			let currentSeconds = "";
			if(tempItem.seconds != ""){
				currentSeconds = Number(tempItem.seconds);
				currentSeconds ++;
	
			}
			
			// console.log("current seconds: ", currentSeconds);

			let timeSinceLastHole = "";
			if(tempItem.played_hole_count == 18 || currentSeconds == ""){
				timeSinceLastHole = "";
			} else {
				timeSinceLastHole = convertSecondsToHour(currentSeconds); 
			}
			let item = {
				"time_on_course": tempItem.time_on_course,
				"golfer_id": tempItem.golfer_id,
				"udid": tempItem.udid,
				"device_type": tempItem.device_type,
				"start_time": tempItem.start_time,
				"start_hole": tempItem.start_hole,
				"cumulated_time": tempItem.cumulated_time,
				"last_hole_number": tempItem.last_hole_number,
				"played_hole_count": tempItem.played_hole_count,
				"play_mode": tempItem.play_mode,
				"seconds": currentSeconds,
				"time_since_last": timeSinceLastHole,
				"club_name": tempItem.club_name,
				"status": tempItem.status,
				"will_play": tempItem.will_play,
				"desc": tempItem.desc,
				"app_version": tempItem.app_version
			}
			tempArray.push(item);
		}

		// console.log("tempArray:", tempArray);
		setGolferList(tempArray);
		global.golferList = tempArray;
	}

	const convertSecondsToHour = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor(d % 3600 / 60);
		var s = Math.floor(d % 3600 % 60);
	
		var hDisplay =h > 9 ? h.toString() : "0" + h.toString();
		var mDisplay = m > 9 ? m.toString() : "0" + m.toString();
		
		return hDisplay + ":" + mDisplay; 
	}
	useEffect(() => {

	// We need to make sure that PubNub is defined

		if (pubnub) {
			// Set the UUID of our user

			pubnub.subscribe({ channels: ["refresh"] });

				const listener = {
						message: envelope => {

							const { message } = envelope;
							console.log("pubnub message: ", message);
							if(btnPress0){
								getGolferList();
							}
								
						}
				}

				pubnub.addListener(listener);

			return () => {
				pubnub.unsubscribeAll();
			};

		};

	}, [pubnub]);

	const getGolferList = () => {


		adminApi.getAllGolferList({})
			.then((response) => {
				console.log("getGolferList Response: ");
				console.log(response);

				if(response.status === 200){

				let tempGolferList = response.data;
				global.golferList = tempGolferList;

			
				setGolferCount(tempGolferList.length);

				if(global.sortMode === "byClub"){
					sortByClub(tempGolferList);
				}else if(global.sortMode === "byClubDecrease"){
					sortByClubDecrease(tempGolferList);
				
				} else if(global.sortMode === "byTime"){
					sortByTime(tempGolferList);
				}else if(global.sortMode === "byTimeDecrease"){
					sortByTimeDecrease(tempGolferList);
				
				}	else if(global.sortMode === "byLastHole"){
					sortByLastHole(tempGolferList);
				
				}else if(global.sortMode === "byLastHoleDecrease"){
					sortByLastHoleDecrease(tempGolferList);
				} else if(global.sortMode === "byCount"){
					sortByCount(tempGolferList);
				} else if(global.sortMode === "byCountDecrease"){
					sortByCountDecrease(tempGolferList);
				}else if(global.sortMode === "byPaceDecrease"){
					sortByPaceDecrease(tempGolferList);
				} else {
					// byPace
					sortByPace(tempGolferList);
				}
		
		
				} else {
					helper.showToast("Error", "Failed to get Hole Time List", 3);
				}
			})
			.catch((error) => {
					console.log("err: ", error);
					helper.showToast("Error", "Invaid auth", 3);
			})
	}

	const getPrevDay = (diff) =>{
		const options = { month: 'numeric', day: 'numeric' };
		const date = new Date();
		console.log("local time: ", date);

		var d = new Date();
		d.setDate(d.getDate() - diff);
		return d.toLocaleDateString('en-US', options);
	}

	const onDetail = e => {
		const index = e.target.id;
		console.log("Edit index: ", index);

		const selectedGolfer = golferList[index];
		console.log("selected Golfer; ", selectedGolfer);

		pubnub.removeListener();

	
			history.push('/admin/clubstatus', {golfer: selectedGolfer, prevDay: prevDay});
		
	
	}

	const onYesDelete = e => {
		
		console.log("delete index: ", global.deleteIndex);

		const selectedGolfer = golferList[global.deleteIndex];
		console.log("selected Golfer; ", selectedGolfer);

		customerApi.deleteGolferRecord({golfer_id: selectedGolfer.golfer_id, desc: selectedGolfer.desc})
            .then((response) => {
                console.log("golfer Entry Delete Response: ");
                console.log(response);
        
                if(response.status === 200){
                
					setAlertShow(false);
					getPrevGolferList(prevDay);
					helper.showToast("Success", "Successfully Deleted", 3);
            
                } else {
                    helper.showToast("Error", "Failed to get Hole Time List", 3);
                }
            })
            .catch((error) => {
                console.log("err: ", error);
                helper.showToast("Error", "Invaid auth", 3);
            })
	
	}

	const deleteGolfer = (e) => {

		setAlertShow(true);
	
		const index = e.target.id;
		console.log("delete index: ", index);
		global.deleteIndex = index;
		
	
	}

	const sortByClub = (newArray) => {
		console.log("Club sort: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.club_name.toUpperCase(); // ignore upper and lowercase
			const nameB = b.club_name.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByClubDecrease = (newArray) => {
		console.log("Club sort decrease: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.club_name.toUpperCase(); // ignore upper and lowercase
			const nameB = b.club_name.toUpperCase(); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByClubAlt = (newArray) => {
		// console.log("Time sort: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.club_name.toUpperCase(); // ignore upper and lowercase
			const nameB = b.club_name.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		global.golferList = tempArray;
	}

	const sortByClubDecreaseAlt = (newArray) => {
		console.log("Club sort decrease: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.club_name.toUpperCase(); // ignore upper and lowercase
			const nameB = b.club_name.toUpperCase(); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		global.golferList = tempArray;
	}


	const onSortByClub = () => {
		console.log("sort by club: ", global.sortMode);
		if(global.sortMode == "byClub"){
			global.sortMode = "byClubDecrease";
			sortByClubDecrease(golferList);
		} else {
			global.sortMode = "byClub";
			sortByClub(golferList);
		}
	}

	const sortByTime = (newArray) => {
		console.log("Time sort: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.start_time.toUpperCase(); // ignore upper and lowercase
			const nameB = b.start_time.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByTimeDecrease = (newArray) => {
		console.log("Time sort decrease: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.start_time.toUpperCase(); // ignore upper and lowercase
			const nameB = b.start_time.toUpperCase(); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const onSortByTime = () => {
		console.log("sort Mode: ", global.sortMode);
		if(global.sortMode == "byTime"){
			global.sortMode = "byTimeDecrease";
			sortByTimeDecrease(golferList);
		} else {
			global.sortMode = "byTime";
			sortByTime(golferList);
		}
	}

	const sortByTimeAlt = (newArray) => {
		// console.log("Time sort: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.start_time.toUpperCase(); // ignore upper and lowercase
			const nameB = b.start_time.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		global.golferList = tempArray;
	}

	const sortByTimeDecreaseAlt = (newArray) => {
		console.log("Time sort decrease: ", newArray);
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.start_time.toUpperCase(); // ignore upper and lowercase
			const nameB = b.start_time.toUpperCase(); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		global.golferList = tempArray;
	}

	const sortByLastHole = (newArray)  => {
		console.log("Last hole sort");
		const tempArray = [...newArray].sort((a, b) => {
			let aLast, bLast;
			if(a.last_hole_number === "Turn"){
				aLast = 9.5;
			}else {
				aLast = a.last_hole_number;
			}

			if(b.last_hole_number === "Turn"){
				bLast = 9.5
			} else {
				bLast = b.last_hole_number;
			}
			const nameA = aLast; // ignore upper and lowercase
			const nameB = bLast; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByLastHoleDecrease = (newArray)  => {
		console.log("Last hole sort");
		const tempArray = [...newArray].sort((a, b) => {
			let aLast, bLast;
			if(a.last_hole_number === "Turn"){
				aLast = 9.5;
			}else {
				aLast = a.last_hole_number;
			}

			if(b.last_hole_number === "Turn"){
				bLast = 9.5
			} else {
				bLast = b.last_hole_number;
			}
			const nameA = aLast; // ignore upper and lowercase
			const nameB = bLast; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const onSortByLastHole = () => {
		if(global.sortMode === 'byLastHole'){
			global.sortMode = 'byLastHoleDecrease';
			sortByLastHoleDecrease(golferList);
		} else {
			global.sortMode = "byLastHole";
			sortByLastHole(golferList);
		}
		
	}

	const sortByLastHoleAlt = (newArray)  => {
		console.log("Last hole sort");
		const tempArray = [...newArray].sort((a, b) => {
			let aLast, bLast;
			if(a.last_hole_number === "Turn"){
				aLast = 9.5;
			}else {
				aLast = a.last_hole_number;
			}

			if(b.last_hole_number === "Turn"){
				bLast = 9.5
			} else {
				bLast = b.last_hole_number;
			}
			const nameA = aLast; // ignore upper and lowercase
			const nameB = bLast; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		global.golferList = tempArray;
	}

	const sortByLastHoleDecreaseAlt = (newArray)  => {
		console.log("Last hole sort");
		const tempArray = [...newArray].sort((a, b) => {
			let aLast, bLast;
			if(a.last_hole_number === "Turn"){
				aLast = 9.5;
			}else {
				aLast = a.last_hole_number;
			}

			if(b.last_hole_number === "Turn"){
				bLast = 9.5
			} else {
				bLast = b.last_hole_number;
			}
			const nameA = aLast; // ignore upper and lowercase
			const nameB = bLast; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		global.golferList = tempArray;
	}

	const sortByCount = (newArray) => {
		console.log("Sort by Count");
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.played_hole_count; // ignore upper and lowercase
			const nameB = b.played_hole_count; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByCountDecrease = (newArray) => {
		console.log("Sort by byCountDecrease");
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.played_hole_count; // ignore upper and lowercase
			const nameB = b.played_hole_count; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const onSortByCount = () => {
		console.log("sort mode: ", global.sortMode);
		if(global.sortMode === 'byCount'){
			global.sortMode = 'byCountDecrease';
			sortByCountDecrease(golferList);
		} else {
			global.sortMode = "byCount";
			sortByCount(golferList);
		}
	}

	const sortByCountAlt = (newArray) => {
		// console.log("Sort by Count");
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.played_hole_count; // ignore upper and lowercase
			const nameB = b.played_hole_count; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		global.golferList = tempArray;
	}

	const sortByCountDecreaseAlt = (newArray) => {
		console.log("Sort by byCountDecrease");
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.played_hole_count; // ignore upper and lowercase
			const nameB = b.played_hole_count; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		global.golferList = tempArray;
	}

	const sortByPace = (newArray) => {
		console.log("Sort by Pace");
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.cumulated_time; // ignore upper and lowercase
			const nameB = b.cumulated_time; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const sortByPaceDecrease = (newArray) => {
		console.log("Sort by Pace");
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.cumulated_time; // ignore upper and lowercase
			const nameB = b.cumulated_time; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		setGolferList(tempArray);
		global.oldGolferList = tempArray;
	}

	const onSortByPace = () => {
		if(global.sortMode === 'byPace'){
			global.sortMode = 'byPaceDecrease';
			sortByPaceDecrease(golferList);
		} else {
			global.sortMode = "byPace";
			sortByPace(golferList);
		}
		
	}

	const sortByPaceAlt = (newArray) => {
		// console.log("Sort by Pace");
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.cumulated_time; // ignore upper and lowercase
			const nameB = b.cumulated_time; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		global.golferList = tempArray;
	}

	const sortByPaceDecreaseAlt = (newArray) => {
		console.log("Sort by Pace");
		const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.cumulated_time; // ignore upper and lowercase
			const nameB = b.cumulated_time; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		global.golferList = tempArray;
	}


	return (
		<>
			<Modal
				className="modal-dialog-centered modal-danger"
				contentClassName="bg-gradient-danger"
				isOpen={alertShow}
				toggle={() => setAlertShow(true)}
			>
				<div className="modal-header">
				{/* <h6 className="modal-title" id="modal-title-notification">
					Your attention is required
				</h6> */}
				<button
					aria-label="Close"
					className="close"
					data-dismiss="modal"
					type="button"
					onClick={() => setAlertShow(false)}
				>
					<span aria-hidden={true}>×</span>
				</button>
				</div>
				<div className="modal-body">
				<div className="py-3 text-center">
					<i className="ni ni-bell-55 ni-3x" />
					<h4 className="heading mt-2">ATTENTION!</h4>
					<div className="text-white">
					Do You Want to Delete This Golfer?
					</div>
				</div>
				</div>
				<div className="modal-footer">
				<Button className="btn-white" color="default" type="button"  onClick={() => onYesDelete()}>
					Yes
				</Button>
				<Button
					className="text-white ml-auto"
					color="link"
					data-dismiss="modal"
					type="button"
					onClick={() => setAlertShow(false)}
				>
					Close
				</Button>
				</div>
			</Modal>
			<CustomerHeader />
			<Card className="bg-default shadow mt--6 mb-5">

				<CardHeader className="bg-transparent border-0">
						
					<Row className="align-items-center">
						<div className="col">
							<h3 className="text-info mb-0 small">Total Users: {golferCount}</h3>
						</div>
						<div className="col">
							<h2 className="text-white mb-0 text-center small">Active Golfers</h2>
						</div>
						<div className="col text-right">
							<Button
								color= {btnPress4 ?"warning" : "info"}
								onClick={() =>onGetPrevDay4()}
								size="sm"
								>
								
								{btnText4}
							</Button>
							<Button
								color= {btnPress3 ?"warning" : "info"}
								onClick={() =>onGetPrevDay3()}
								size="sm"
								>
								
								{btnText3}
							</Button>
							<Button
								color= {btnPress2 ?"warning" : "info"}
								onClick={() =>onGetPrevDay2()}
								size="sm"
								>
								
								{btnText2}
							</Button>
							<Button
								color= {btnPress1 ?"warning" : "info"}
								onClick={() =>onGetPrevDay1()}
								size="sm"
								>
								
								{btnText1}
							</Button>
							<Button
								color= {btnPress0 ?"warning" : "info"}
								onClick={() =>onGetToday()}
								size="sm"
								>
								
								{btnText0}
							</Button>
						</div>
					</Row>

				</CardHeader>

				<Table
					id="table-dashboard-user"
					className={`table-dark`}
					responsive
				>
					<thead className="thead-dark">
							
						<tr>
							<th >
								<div className="text-white mb-0 text-center">ID</div>
							</th>

							<th >
								<div className="text-white mb-0 text-center">Name</div>
							</th>

							<th >
								<div className="text-white mb-0 text-center">Version</div>
							</th>

							<th  onClick={() => onSortByClub()}>
								<div className="text-white mb-0 text-center">Club</div>
								<div className="text-white mb-0 text-center">Name <i className="fas fa-sort"></i></div>
							</th>

							<th >
								<div className="text-white mb-0 text-center">9/18</div>
							</th>
	
							<th onClick={() => onSortByTime()}>
								<div >
									<div className="text-white mb-0 text-center">Start</div>
									<div className="text-white mb-0 text-center">Time <i className="fas fa-sort"></i></div>
								</div>   
							</th>
							<th>
								<div className="text-white mb-0 text-center">Start</div>
								<div className="text-white mb-0 text-center">Hole</div>
							</th>
							<th onClick={() => onSortByLastHole()}>
								<div className="text-white mb-0 text-center">Last Hole</div>
								<div className="text-white mb-0 text-center">Played <i className="fas fa-sort"></i></div>
							</th>
							<th onClick={() => onSortByCount()} >
								<div className="text-white mb-0 text-center"># of Holes</div>
								<div className="text-white mb-0 text-center">Played <i className="fas fa-sort"></i></div>
							</th>
							<th onClick = {() => onSortByPace()}>
								<div className="text-white mb-0 text-center">Current</div>
								<div className="text-white mb-0 text-center">Pace <i className="fas fa-sort"></i></div>
							</th>
							<th>
								<div className="text-white mb-0 text-center">Time Thru</div>
								<div className="text-white mb-0 text-center">Last Hole</div>
							</th>

							<th>
								<div className="text-white mb-0 text-center">Time Since</div>
								<div className="text-white mb-0 text-center">Last Hole</div>
							</th>

							<th>
								<div className="text-white mb-0 text-center">Status</div>
							</th>

							<th >
								<div className="text-white mb-0">Action</div>
							</th>

							
						</tr>
					</thead>
					<tbody>
					{
						golferList.length > 0 ?
							golferList.map((item, index) =>
								<tr key={index}>
									<td>
										<div className="text-white mb-0 text-center">
											{item.golfer_id}
										</div>
									</td>
									<td>
										<div className="text-white mb-0 text-center">
											{item.udid}
										</div>
									</td>
									<td>
										<div className="text-white mb-0 text-center">
											{item.app_version}
										</div>
									</td>
									<td>
										<div className="text-white mb-0 text-center">
											{item.club_name}
										</div>
									</td>
									<td>
										<div className="text-white mb-0 text-center">
											{item.will_play}
										</div>
									</td>
									<td>
										<div className="text-white mb-0 text-center">
											{item.start_time}
										</div>
									</td>
									<td>
										<div className="text-white mb-0 text-center">
											{item.start_hole}
										</div>
									</td>
									<td>
										<div className="text-white mb-0 text-center">
											{item.last_hole_number}
										</div>
									</td>
									<td>
										<div className="text-white mb-0 text-center">
											{item.played_hole_count}
										</div>
									</td>
									
									<td>
										{
											item.last_hole_number == "" ? 
											""
											:
											item.cumulated_time == 0 ?
											<div className="text-info mb-0 text-center">0</div>
											:
											<></>
										}
										{
											item.last_hole_number == "" ? 
											""
											:
											item.cumulated_time > 0 ?
											<div className="text-success mb-0 text-center"><i className="fa fa-arrow-up" />+{item.cumulated_time}</div>
											:
											<></>
										}
										{
											item.last_hole_number == "" ? 
											""
											:
											item.cumulated_time < 0 ?
											<div className="text-warning mb-0 text-center"><i className="fa fa-arrow-down" />{item.cumulated_time}</div>
											:
											<></>
										}
									</td>

									<td>
										<div className="text-white mb-0 text-center">
											{item.time_on_course}
										</div>
									</td>

									<td>
										<div className="text-white mb-0 text-center">
											{ item.status == 0 ? "" : item.time_since_last == undefined ? "" : item.time_since_last}
										</div>
									</td>
									<td>
										<div className="text-white mb-0 text-center">
											{item.status == 0 ? "Not Available" : "Available"}
										</div>
									</td>
									{/* {
										btnPress0 ? 
										<td className=" align-items-center">
											<Button
												color="success"
												size="sm"
												id={index}
												onClick={(e) => onDetail(e)}
											>
												Detail
											</Button>
										</td>
										:
										<></>
									} */}
									<td className=" align-items-center">
										<Button
											color="success"
											size="sm"
											id={index}
											onClick={(e) => onDetail(e)}
										>
											Detail
										</Button>

										<Button
											color="warning"
											size="sm"
											id={index}
											onClick={(e) => deleteGolfer(e)}
										>
											Delete
										</Button>
									</td>
									
								</tr>
							)
						:
						<></>
					}

				</tbody>
			</Table>
		</Card>
	</>
	);
};

export default AdminSummary;