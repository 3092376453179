import React, {useState, useEffect} from "react";
import './map.css';

import {
    useParams
} from "react-router-dom";

import { usePubNub } from 'pubnub-react';

function Map(props) {
    let interval;

    const pubnub = usePubNub();

    const _loadScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('async', 'true');
            script.setAttribute('src', src);
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    }
    
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });
    
    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }
    
    useEffect(() => {

        window.addEventListener('resize', detectSize);
        getRotationData();
        return () => {
            window.removeEventListener('resize', detectSize);
            clearInterval(interval);
        }

    }, [windowDimenion]);

    const getRotationData = () => {

        const queryParams = new URLSearchParams(window.location.search);
        const parentCourse = queryParams.get('parentCourse');
        const childCourse = queryParams.get('childCourse');
        const rotation = queryParams.get('rotation');
        const rotationType = queryParams.get('rotationType');
        const pinNumber = queryParams.get('pinNumber');
        const userName = queryParams.get('userName');

        global.userName = userName;

        console.log(parentCourse, childCourse, pinNumber); // 55 test null

        global.currentLati = 0;
        global.currentLong = 0;
        global.fontLarger = false;
        global.pressLati = 0;
        global.pressLong = 0;

        const API_URL = 'https://5yjcrgi025.execute-api.us-east-1.amazonaws.com/accupin_dev/';
        const imageFlagBaseUrl = "https://accupin-app-storage212237-dev.s3.amazonaws.com/public/club_flags/";
        const url =  API_URL + `getpinneargreen`;

        fetch(url, {

            method: 'POST',
    
            headers: {
    
            Accept: 'application/json',
    
            'Content-Type': 'application/json'
    
            },
    
            body: JSON.stringify({
    
                parent_course_name: parentCourse,
    
                child_course_name: childCourse,
    
                pin_number: pinNumber,
    
                rotation: rotation,
    
                type_id: rotationType
    
            })
    
        })
    
        .then((response) => response.json())
    
        .then((json) => {
    
            console.log(json);

            if(json.hasOwnProperty("long_factor")){

                global.longFactor = json['long_factor'];

                global.rotationLati = Number(json["rotation_lat"]);

				global.rotationLong = Number(json["rotation_long"]);

                global.customFlagEnable = json["custom_flag_enable"];

                let flagName = "https://accupin-app-storage212237-dev.s3.amazonaws.com/public/club_flags/default_flag/flag.png";
                if(global.customFlagEnable){
                    flagName = imageFlagBaseUrl + parentCourse.toLowerCase().split(" ").join("_") + "/" + childCourse.toLowerCase().split(" ").join("_") + "/flag.png";
                } 

                global.flagUrl = flagName;

            } 

            initCourseData(json.course_data);
    
        })
    
        .catch((error) => {
    
            console.warn(error);
    
        });
    }

    const initCourseData = (courseData) => {
        console.log("courseData: ", courseData);

        let centerLati, centerLong, deltaLati, deltaLong, heading;

        if(courseData.hasOwnProperty("upper_left_lat")){

			global.upperLeftLati = courseData["upper_left_lat"];		

		}

		if(courseData.hasOwnProperty("upper_left_long")){

			global.upperLeftLong = courseData["upper_left_long"];			

		}

        if(courseData.hasOwnProperty("points_hole_view")){

			let pointsHoleViewData = courseData["points_hole_view"]

			console.log("Hole view data: ", pointsHoleViewData);

			centerLati = pointsHoleViewData["region_lat"];

			centerLong = pointsHoleViewData["region_long"];

			deltaLati = Math.abs(global.upperLeftLati - centerLati);

			deltaLong = Math.abs(global.upperLeftLong - centerLong);

			let latiFactor = 122000;

			let longFactor = global.longFactor;

			let deltaLatiAlt = deltaLati * latiFactor / longFactor;

			let detla = deltaLatiAlt > deltaLong ? deltaLatiAlt : deltaLong;

			console.log("detal lati and long: ", deltaLatiAlt, deltaLong);

			let zoom = Math.round(Math.log(360 / detla) / Math.LN2)

			heading = getHeadingFromUL(centerLati, centerLong, global.upperLeftLati, global.upperLeftLong);

			heading = heading - 180;

			console.log("Distance and heading : ", heading, zoom, detla);

			if(courseData.hasOwnProperty("orientation")){

				heading = courseData["orientation"] * -1;

			}

			console.log("new heading: ", heading);

			if(pointsHoleViewData.hasOwnProperty("point_array")){

				let pointArray = pointsHoleViewData["point_array"];

				let tempKeys = Object.keys(pointArray);

				var tempArray = [];

				for(let i = 0; i < tempKeys.length; i++){

					const itemKey = tempKeys[i];

					const item = pointArray[itemKey];

					const lati = item["lat"];

					const long = item["long"];

					let distance = 0;

					if(global.currentLong != 0){

						distance = getDistance(lati, long, global.currentLati, global.currentLong);

					}

					distance = Math.round(distance).toString();

					let color = "blue";

					if(item.hasOwnProperty("color")){

						color = item["color"];

					}

					let fontColor = "white";

					if(item.hasOwnProperty("font_color")){

						fontColor = item["font_color"];

					}

					let newItem = {

						latitude: lati,

						longitude: long,

						color: color,

						fontColor: fontColor,

						distance: distance

					}

					tempArray.push(newItem);			

				}

				global.holeViewData = tempArray;
             

				console.log("New Hole View Data: ", tempArray);
               

			}

		}

        initMap(centerLati, centerLong, deltaLati, deltaLong, heading);
    }

    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
       
        const userName = queryParams.get('userName');

		if (pubnub) {

		  	pubnub.setUUID(userName);

			pubnub.subscribe({ channels: ["location"] });

			return () => {

				pubnub.removeListener();

				pubnub.unsubscribeAll();

			};

		};

	}, [pubnub]);


    const initMap = (centerLati, centerLong, deltaLati, deltaLong, heading) => {
        Promise.all([
          _loadScript('https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js'),
        ]).then(() => {
    
            console.log(window.mapkit);
        
            var mapkit = window.mapkit;

            // const tokenID = "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlpDTjVWOTlaUk4ifQ.eyJpc3MiOiJISEs1OTIzTVc4IiwiaWF0IjoxNjU2NjIyNDU4LCJleHAiOjE2ODgwODMyMDAsIm9yaWdpbiI6Imh0dHA6Ly81NC4yMzQuMjQ0LjIxIn0.lQlgICXm4u4oGUBEDka6_BiDDVKd6LwC4I904CyurddVpfkJSthVsK--B0Fv572uxw9KsDRJT9wlAEFaIGpVpg";  //server
            const tokenID = "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlpDTjVWOTlaUk4ifQ.eyJpc3MiOiJISEs1OTIzTVc4IiwiaWF0IjoxNjk3NTgyMDc0LCJleHAiOjE3MjkxMjMyMDAsIm9yaWdpbiI6Imh0dHA6Ly8zLjIxMy40NS4xNTIifQ.DERDRRRMYWeDQ6fyseg6m0wF2JUrDinb4F1XJyeH283ZQbKungmuCxfwJZgCu1f5Hn3twptus3LN-q9pNux4Zg";
            mapkit.init({
                authorizationCallback: function(done) {
                    done(tokenID);
                }
            });
        
            var map = new mapkit.Map("map");
            var center = new mapkit.Coordinate(centerLati, centerLong);
            var span = new mapkit.CoordinateSpan(deltaLati* 1.6, deltaLong * 1.6); // latitude delta, longitude delta
            var region = new mapkit.CoordinateRegion(center, span);
            map.region = region;
            map.mapType = "satellite";
            map.showsZoomControl = true;
            // map.showsMapTypeControl = true;
            map.showsUserLocation = true;
            // var zoomRange = new mapkit.CameraZoomRange(2, 1500000)
            // map.cameraZoomRange = zoomRange;
            // map.cameraBoundary = region;
            map.rotation = -1 * heading;

            var rotationOption = {
                data: {fontColor: 'white', color: "white", flagUrl: global.flagUrl}
            }

            let rotationCoordinate = new mapkit.Coordinate(global.rotationLati, global.rotationLong);
            var rotationAnnotation = new mapkit.Annotation(rotationCoordinate, rotationFactory, rotationOption);
            map.addAnnotation(rotationAnnotation);

           
            var pointAnnotation;
        
            map.addEventListener('single-tap', function(event){
                console.log(event);
                const {pointOnPage} = event;
                console.log(pointOnPage);
        
                const pressPointCoords = map.convertPointOnPageToCoordinate(pointOnPage);

                global.pressLati = pressPointCoords.latitude;
                global.pressLong = pressPointCoords.longitude;

                if(pointAnnotation){
                    map.removeAnnotation(pointAnnotation);
                }

                var distance = "";

                if(global.currentLati){
                    distance = Math.round(getDistance(global.currentLati, global.currentLong, pressPointCoords.latitude, pressPointCoords.longitude));

                    let toRotation = Math.round(getDistance(global.rotationLati, global.rotationLong, pressPointCoords.latitude, pressPointCoords.longitude));

                    distance = distance.toString() + '/' + toRotation.toString(); 
                }

                var pointOption = {
                    data: {fontColor: 'black', color: "black", distance: distance, fontLarger: global.fontLarger}
                }
                pointAnnotation = new mapkit.Annotation(pressPointCoords, factory, pointOption);
                map.addAnnotation(pointAnnotation);

                console.log(pressPointCoords);
            });

            var holeViewAnnotationArray = [];
   
            var currentLocationAnnotation;

            let acoordinate, annotation;

            const listener = {
                message: envelope => {
                    console.log("pubnub result: ", envelope);
                    const { message, publisher } = envelope;
                    const {latitude, longitude, fontLarger } = message;

                    if(publisher == global.userName) {
                        global.fontLarger = fontLarger;

                        console.log("message: ", message);

                        global.currentLati = Number(latitude);
                        global.currentLong = Number(longitude);

                        if(pointAnnotation){
                            map.removeAnnotation(pointAnnotation);
                        }
        
                        var distance = ""
        
                        if(global.currentLati != 0 && global.pressLati != 0){
                            distance = Math.round(getDistance(global.currentLati, global.currentLong, global.pressLati, global.pressLong));
        
                            let toRotation = Math.round(getDistance(global.rotationLati, global.rotationLong, global.pressLati, global.pressLong));
        
                            distance = distance.toString() + '/' + toRotation.toString(); 
                        }
        
                        var pressPointCoords = new mapkit.Coordinate(global.pressLati, global.pressLong);
                    
                        var pointOption = {
                            data: {fontColor: 'black', color: "black", distance: distance, fontLarger: fontLarger}
                        }
                        pointAnnotation = new mapkit.Annotation(pressPointCoords, factory, pointOption);
                        map.addAnnotation(pointAnnotation);
        
                        if(currentLocationAnnotation){
                            map.removeAnnotation(currentLocationAnnotation);
                        }
                        var currentCoords = new mapkit.Coordinate(global.currentLati, global.currentLong);
                        var currentOptions = {
                            data: {
                                color: "blue"
                            }
                        }
                        currentLocationAnnotation = new mapkit.Annotation(currentCoords, currentFactory, currentOptions);
                        map.addAnnotation(currentLocationAnnotation);
        
        
                        if(holeViewAnnotationArray.length > 0){
                            for(let i = 0; i < holeViewAnnotationArray.length; i++){
                                map.removeAnnotation(holeViewAnnotationArray[i]);
                            }
                        }
        
                        holeViewAnnotationArray=[];
        
                        for(let i = 0; i < global.holeViewData.length; i++){
                            var item = global.holeViewData[i];
                            var distance = getDistance(global.currentLati, global.currentLong, item.latitude, item.longitude);
                            var options = {
                                
                                data: { fontColor: item.fontColor, color: item.color, distance: Math.round(distance), fontLarger: fontLarger }
                            };
                            acoordinate = new mapkit.Coordinate(item.latitude, item.longitude);
                            annotation = new mapkit.Annotation(acoordinate, factory, options);
                            map.addAnnotation(annotation);
                            holeViewAnnotationArray.push(annotation);
                        }

                    }

                    
                }
            };

            pubnub.addListener(listener);
    
        } );
    }

    return (
        <div style={{width: "100%", height: windowDimenion.winHeight *  0.9}} id="map" />
    )
}

export default Map;


const rotationFactory = (coordinate, options) => {
 
    const { flagUrl } = options.data;

    console.log("flagUrl: ", flagUrl);
    var div = document.createElement("div");
    div.className = "circle-annotation";
    var image = new Image();
    image.src =flagUrl
    image.className = "image-size"
    div.appendChild(image);
  
    return div;
};

const factory = (coordinate, options) => {
 
    const { data} = options;

    var div = document.createElement("div");

    var style = document.createElement('style');
    style.type = 'text/css';
    

    if(data.fontLarger){
        style.innerHTML = '.cssClass' + data.color + ' {   width: 6px;   height: 6px; border-radius: 50%; background-color:'+ data.color + ';}';
    } else {
        style.innerHTML = '.cssClass' + data.color + ' {   width: 5px;   height: 5px; border-radius: 50%; background-color:'+ data.color + ';}';
    }
    document.getElementsByTagName('head')[0].appendChild(style);
    div.className = "cssClass" + data.color;

    var distance = div.appendChild(document.createElement("div"));
    distance.textContent = data.distance;

    var textStyle = document.createElement('style');
    textStyle.type = 'text/css';
    if(data.fontLarger){
        textStyle.innerHTML = '.cssClassLarge' + data.fontColor + '{position: absolute; top: -23px; left: -9px; font-size: 17px; font-family: sans-serif; color: ' + data.fontColor + '; font-weight: 600;}';
        document.getElementsByTagName('head')[0].appendChild(textStyle);
        distance.className = "cssClassLarge" + data.fontColor
    } else {
        textStyle.innerHTML = '.cssClassSmall' + data.fontColor + '{position: absolute; top: -20px; left: -7px; font-size: 13px; font-family: sans-serif; color: ' + data.fontColor + ';}';
        document.getElementsByTagName('head')[0].appendChild(textStyle);
        distance.className = "cssClassSmall" + data.fontColor
    }

    return div;
};

const currentFactory = (coordinate, options) => {
 
    const { data} = options;

    var div = document.createElement("div");
    div.className = "currentIndicator"

   
    return div;
};

export function getDistance (

    lati1, long1, lati2, long2

) {

    const step1 = degsToRads(90 - lati2);

    const step2 = Math.cos(step1);

    const step3 = degsToRads(90-lati1);

    const step4 = Math.cos(step3);

    const step5 = step4 * step2;

    const step6 = degsToRads(90 - lati2);

    const step7 = Math.sin(step6);

    const step8 = degsToRads(90 - lati1);

    const step9 = Math.sin(step8);

    const step10 = step9 * step7;

    const step11 = degsToRads(long2 - long1);

    const step12 = Math.cos(step11);

    const step13 = step12 * step10;

    const step14 = step13 + step5;

    const step15 = Math.acos(step14);

    const step16 = step15 * 6371;

    const step17 = step16 / 1.609;

    const step18 = step17 * 1760;



    return step18;

}


export function getHeadingFromUL(currentLati, currentLong, upperLeftLati, upperLeftLong) {



    let step1 = upperLeftLati;

    let step2 = degsToRads(step1);

    let step3 = Math.cos(step2);

    let step4 = currentLati;

    let step5 = degsToRads(step4);

    let step6 = Math.sin(step5);

    let step7 = step3 * step6;

    let step8 = upperLeftLati;

    let step9 = degsToRads(step8);

    let step10 = Math.sin(step9);

    let step11 = currentLati;

    let step12 = degsToRads(step11);

    let step13 = Math.cos(step12);

    let step14 = step10 * step13

    let step15 = currentLong - upperLeftLong;

    let step16 = degsToRads(step15);

    let step17 = Math.cos(step16);

    let step18 = step14 * step17;

    let step19 = step7 - step18;

    let step20 = currentLong - upperLeftLong;

    let step21 = degsToRads(step20);

    let step22 = Math.sin(step21);

    let step23 = currentLati;

    let step24 = degsToRads(step23);

    let step25 = Math.cos(step24);

    let step26 = step22 * step25;

    let step27 = Math.atan2(step26, step19);

    let step28 = radsToDegs(step27);

    let step29 = step28 % 360;



    let heading = step29;



    return heading

  }

  export function degsToRads (deg){

    return (deg * Math.PI) / 180.0;

}



export function radsToDegs (rad){

    return rad * 180 / Math.PI;

}