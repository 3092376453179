import { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  Input,
  Table,
  Container,
  Row,
  Modal,
  Button
} from "reactstrap";

import CustomerHeader from "../../components/Headers/CustomerHeader";

import helper from '../../services/helper.service';
import customerApi from '../../services/customer.service';
import session from '../../services/session.service';

function  SetName(){

    const [isRead, setIsRead] = useState(false);
    
    const [clubId, setClubId] = useState(session.get('clubInfo').clubId);
    const [clubName, setClubName] = useState(session.get('clubInfo').clubName);

    const [userList, setUserList] = useState([]);
    const [editIndex, setEditIndex] = useState(0);
    const [frontTimeList, setFrontTimeList] = useState([]);

    const [alertShow, setAlertShow] = useState(false);
    const[inputText, setInputText] = useState("");

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        
        console.log("clubId: ", clubId);
        console.log("ClubName: ", clubName);

        if(!isRead){
            setIsRead(true);
            getUserList(clubId);
        }
    
    })

    const getUserList = (clubId) => {
        customerApi.getUserList({club_id: clubId})
            .then((response) => {
                console.log("HoleTimeList Response: ");
                console.log(response);
                global.sortNameMode = "byUpdated";
                if(response.status === 200){
                    let tempUserList = response.data;
                    global.userList = tempUserList;
                    console.log(tempUserList)
                    const tempArray = [...tempUserList].sort((a, b) => {
                        const nameA = a.updated_at; // ignore upper and lowercase
                        const nameB = b.updated_at; // ignore upper and lowercase
                        if (nameA > nameB) {
                            return -1;
                        }
                        if (nameA < nameB) {
                            return 1;
                        }
                
                        // names must be equal
                        return 0;
                    });
                    
                    setUserList(tempArray);
                    // setUserList(tempUserList);
                } else {
                    helper.showToast("Error", "Failed to get Hole Time List", 3);
                }
            })
            .catch((error) => {
                console.log("err: ", error);
                helper.showToast("Error", "Invaid auth", 3);
            })
    }

    const onChange = e => {
        const index = e.target.id;
        const value = e.target.value;
        console.log("value: ", value);
        setInputText(value);
    }

    const onCancel = () => {
        setIsEdit(false);
        setUserList(global.userList);
    }

    const onSave = () => {

        let tempUserList = [];

        for(let i = 0; i < userList.length; i++){

            let item = userList[i];
            let tempItem;

            if(i == editIndex){
                tempItem = {
                    golfer_id: item.golfer_id,
                    udid: item.udid,
                    name: inputText,
                    created_at: item.created_at,
                    updated_at: item.updated_at 
                }
            } else {
                tempItem = item;
            }

            tempUserList.push(tempItem);
        }

        const tempArray = [...tempUserList].sort((a, b) => {
            const nameA = a.updated_at; // ignore upper and lowercase
            const nameB = b.updated_at; // ignore upper and lowercase
            if (nameA > nameB) {
                return -1;
            }
            if (nameA < nameB) {
                return 1;
            }
            return 0;
        });

  
        customerApi.updateUserList({club_id: clubId, data: tempArray})
            .then((response) => {
                console.log("updateUserList Response: ");
                console.log(response);

                global.userList = userList;

                if(response.status === 200){
                setIsRead(false);
                window.location.reload();
                } else {
                helper.showToast("Error", "Failed to update Time List", 3);
                }
            })
            .catch((error) => {
                console.log("err: ", error);
                helper.showToast("Error", "Invaid auth", 3);
            })
    }
    const onDelete = (e) => {
        setAlertShow(true);

        const index = e.target.id;
        console.log("delete index: ", index);

        global.deleteIndex = index;

        
    }

    const onEdit = (e) => {
        const index = e.target.id;
        console.log("eidt id: ", index);
        global.editIndex = index;
        setInputText(userList[index].name);
        setEditIndex(index);

        setIsEdit(true);
        
    }

    const onYesDelete = (e) => {

        const selectedGolfer = userList[global.deleteIndex];
        console.log("selected Golfer; ", selectedGolfer);
        customerApi.deleteGolfer({golfer_id: selectedGolfer["golfer_id"]})
            .then((response) => {
                console.log("updateUserList Response: ");
                console.log(response);

                if(response.status === 200){

                    window.location.reload();
                } else {
                helper.showToast("Error", "Failed to update Time List", 3);
                }
            })
            .catch((error) => {
                console.log("err: ", error);
                helper.showToast("Error", "Invaid auth", 3);
            })
    }

    const onSortByCreated = () => {
        if(global.sortNameMode == "byCreated"){
            global.sortNameMode = "byCreatedDecrease";
            sortByCreatedDecrease(userList);
        } else {
            global.sortNameMode = "byCreated";
            sortByCreated(userList);
        }
     
    }

    const sortByCreated = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.created_at; // ignore upper and lowercase
			const nameB = b.created_at; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setUserList(tempArray);
    }

    const sortByCreatedDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.created_at; // ignore upper and lowercase
			const nameB = b.created_at; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setUserList(tempArray);
    }

    const onSortByUpdated = () => {
        if(global.sortNameMode == "byUpdated"){
            global.sortNameMode = "byUpdatedDecrease";
            sortByUpdatedDecrease(userList);
        } else {
            global.sortNameMode = "byUpdated";
            sortByUpdated(userList);
        }
     
    }

    const sortByUpdated = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.updated_at; // ignore upper and lowercase
			const nameB = b.updated_at; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setUserList(tempArray);
    }

    const sortByUpdatedDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.updated_at; // ignore upper and lowercase
			const nameB = b.updated_at; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setUserList(tempArray);
    }

    const onSortByName = () => {
        if(global.sortNameMode == "byName"){
            global.sortNameMode = "byNameDecrease";
            sortByNameDecrease(userList);
        } else {
            global.sortNameMode = "byName";
            sortByName(userList);
        }
     
    }

    const sortByName = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.name; // ignore upper and lowercase
			const nameB = b.name; // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setUserList(tempArray);
    }

    const sortByNameDecrease = (newArray) => {
        const tempArray = [...newArray].sort((a, b) => {
			const nameA = a.name; // ignore upper and lowercase
			const nameB = b.name; // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
		setUserList(tempArray);
    }

    return (
    <>
        <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={alertShow}
            toggle={() => setAlertShow(false)}
        >
            <div className="modal-header">
                {/* <h6 className="modal-title" id="modal-title-notification">
                  Your attention is required
                </h6> */}
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => setAlertShow(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="py-3 text-center">
                    <i className="ni ni-bell-55 ni-3x" />
                    <h4 className="heading mt-2">ATTENTION!</h4>
                    <div className="text-white">
                        Do You Want to Delete This User?
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <Button className="btn-white" color="default" type="button"  onClick={() => onYesDelete()}>
                    Yes
                </Button>
                <Button
                    className="text-white ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setAlertShow(false)}
                >
                    Close
                </Button>
            </div>
        </Modal>
        <CustomerHeader />
     
        <Container className="mt--7 mb-8" fluid >
            <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h2 className="text-white mb-0 text-center">Set Name</h2>
                        </div>                
                    </Row>
                </CardHeader>
                <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                >
                    <thead className="thead-dark">
                        <tr>
                            <th ><div className="text-white mb-0 text-center">No</div></th>
                            <th ><div className="text-white mb-0 text-center">User ID</div></th>

                            <th onClick = {() => onSortByName()}>
                                <div className="text-white mb-0 text-center">
                                    Name<i className="fas fa-sort"></i>
                                </div>
                            </th>
                            <th onClick = {() => onSortByUpdated()}>                       
                                <div className="text-white mb-0 text-center">
                                    Updated At<i className="fas fa-sort"></i>
                                </div>
                            </th>
                            <th onClick = {() => onSortByCreated()}>
                                <div className="text-white mb-0 text-center">
                                    Created At<i className="fas fa-sort"></i>
                                </div>
                            </th>
                            <th >
                                <div className="text-white mb-0 text-center">
                                   Action
                                </div>
                            </th>
                         
                        </tr>
                    </thead>
                    <tbody>
                    {
                        userList.length > 0 ?
                            userList.map((item, index) => 
                        
                            <tr key={index}>
                                <th >
                                    <div className="text-white mb-0 text-center">  {index + 1} </div>
                                </th>
                                <th>
                                    <div className="text-white mb-0 text-center">  {item.udid} </div>
                                </th>
                                {
                                    isEdit && (index == editIndex) ?
                            
                                    
                                    
                                    <td>
                                    <div style={{paddingLeft: "40px", paddingRight: "40px"}} className="align-items-center">
                                        <Input 
                                            className="form-control-flush"
                                            id={index}
                                            value={inputText}
                                            onChange={(e) => onChange(e)}
                                            type="text"
                                        />
                                    </div>
                                    </td>
                                    :
                                    <td>
                                        
                                        <div className="text-white mb-0 text-center">{item.name}</div>
                                    </td>
                 
                                }
                                <td>
                                    <div className="text-white mb-0 text-center">
                                    {
                                    
                                        item.updated_at
                                    }
                                    </div>
                                </td>
                                <td>
                                    <div className="text-white mb-0 text-center">
                                    {
                                    
                                        item.created_at
                                    }
                                    </div>
                                </td>

                                {
                                    isEdit && (index == editIndex) ?
                                    <td className=" align-items-center">
                                        <Button
                                            color="info"
                                    
                                            onClick={() => onSave()}
                                        
                                            size="sm"
                                            id={index}
                                        >
                                        
                                            Save
                                        </Button>
                                        <Button
                                            color="warning"
                                            size="sm"
                                            id={index}
                                            onClick={() => onCancel()}
                                        >
                                            Cancel
                                        </Button>
                                    </td>
                                    :
                                    <td className=" align-items-center">
                                        <Button
                                            color="info"
                                    
                                            onClick={(e) => onEdit(e)}
                                        
                                            size="sm"
                                            id={index}
                                        >
                                        
                                            Edit
                                        </Button>
                                        <Button
                                            color="warning"
                                            size="sm"
                                            id={index}
                                            onClick={(e) => onDelete(e)}
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                }
                            </tr>                       
                        )
                        :
                        <></>
                    }
                    </tbody>
                </Table>
            </Card>
        </Container>
    </>
    );
};

export default SetName;