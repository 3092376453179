import http from "./http.service";
import session from "./session.service";

const path = {
   
    getBoxTimeList: 'customer/getBoxTimeList',
    updateBoxTimeList: 'customer/updateBoxTimeList',
    getGolferList: 'customer/getGolferList',
    getPrevGolferList: 'customer/getPrevGolferList',
    getUserList: 'customer/getUserList',
    updateUserList: 'customer/updateUserList',
    getIndividualStatus: 'customer/getIndividualStatus',
    getPrevIndividualStatus: 'customer/getPrevIndividualStatus',
    deleteGolfer: 'customer/deleteGolfer',
    getReportHole: 'customer/getReportHole',
    getReportHalfRound: 'customer/getReportHalfRound',
    getReportRound: 'customer/getReportRound',
    getReportSummary: 'customer/getReportSummary',
    deleteGolferRecord: 'customer/deleteGolferRecord',
  
}

export default {
  
    getBoxTimeList: function(body){
        return http.post(path.getBoxTimeList, body, {});
    },
    updateBoxTimeList: function(body){
        return http.post(path.updateBoxTimeList, body, {});
    },
    getGolferList: function(body){
        return http.post(path.getGolferList, body, {});
    },
    getPrevGolferList: function(body){
        return http.post(path.getPrevGolferList, body, {});
    },
    getIndividualStatus: function(body){
        return http.post(path.getIndividualStatus, body, {});
    },
    getPrevIndividualStatus: function(body){
        return http.post(path.getPrevIndividualStatus, body, {});
    },
    getUserList: function(body){
        return http.post(path.getUserList, body, {});
    },
    updateUserList: function(body){
        return http.post(path.updateUserList, body, {});
    },
    deleteGolfer: function(body){
        return http.post(path.deleteGolfer, body, {});
    },
    getReportHole: function(body){
        return http.post(path.getReportHole, body, {});
    },
    getReportHalfRound: function(body){
        return http.post(path.getReportHalfRound, body, {});
    },
    getReportRound: function(body){
        return http.post(path.getReportRound, body, {});
    },
    getReportSummary: function(body){
        return http.post(path.getReportSummary, body, {});
    },
    deleteGolferRecord: function(body){
        return http.post(path.deleteGolferRecord, body, {});
    },
}