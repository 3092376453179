import React from 'react';
import './Marker.css';

const Marker = (props) => {
    const { color, name, id, order, startTime, startHole, lastHole, playedCount, currentPace, timeOnCourse, timeOnSince } = props;
    const [showInfo, setShowInfo] = React.useState(false);
    return (
      <div className="marker"
        style={{ backgroundColor: color, cursor: 'pointer'}}
        onMouseOver ={() => setShowInfo(true)} 
        onMouseOut ={() => setShowInfo(false)} 
        // title={name}
      >
        <div style={{color: color == "yellow" ? "blue" : "white", paddingLeft: 4, paddingTop: 4, fontSize: 14}}>
            {order}
        </div>
        {
            showInfo ? 

            <div style={{height: "200px", width: "250px", fontSize: 16, marginTop: -230, backgroundColor: "white", justifyContent: "center", alignContent: "center", alignItems: "center", padding: 10, borderColor: "green", borderWidth: 10, borderRadius: "10px"}}>
                <div style={{marginTop: 2, color: "black"}}>
                    Name: {name}
                </div>
                <div style={{marginTop: 4, color: "black", flexDirection: "row"}}>
                    Start Time: {startTime}
                </div>
                <div style={{marginTop: 4, color: "black", flexDirection: "row"}}>
                    Start Hole: {startHole}
                </div>
                <div style={{marginTop: 4, color: "black", flexDirection: "row"}}>
                Last Hole Played: {lastHole}
                </div>
                <div style={{marginTop: 4, color: "black", flexDirection: "row"}}>
                    # of Holes Played: {playedCount}
                </div>
                <div style={{marginTop: 4, color: "black", flexDirection: "row"}}>
                Current Pace: {currentPace}
                </div>
                <div style={{marginTop: 4, color: "black", flexDirection: "row"}}>
                Time Thru Last Hole: {timeOnCourse}
                </div>
                <div style={{marginTop: 4, color: "black", flexDirection: "row"}}>
                Time Since Last Hole: {timeOnSince}
                </div>
            
            </div>
            :
            <></>
        }
        
        </div>
    );
  };

  export default Marker;