import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import PubNub from "pubnub";

import { PubNubProvider } from "pubnub-react";
import App from './route/app';


const pubnub = new PubNub({

  publishKey: 'pub-c-7b5a0611-e6b3-4feb-840f-f6e02794af50',

  subscribeKey: 'sub-c-10cf7b93-aa71-4a5b-b9cc-5c5bcd23a490',
  autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
  restore: true, // enable catchup on missed messages

});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <PubNubProvider client={pubnub}>
      <App/>
    </PubNubProvider>
  </BrowserRouter>,
);

