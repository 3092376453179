import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)


// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Modal,
  Button
} from "reactstrap";

import { useHistory } from 'react-router-dom';
import moment from "moment";

import Header from "../../components/Headers/Header.js";

import helper from '../../services/helper.service';
import adminApi from '../../services/admin.service';
import session from '../../services/session.service';
import environments from "../../environments/index.js";
import EditClub from "../EditClub/index.js";

function  Dashboard(props){

  const history = useHistory();
  const [clubList, setClubList] = useState([]);
  const [isRead, setIsRead] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [clubAllList, setClubAllList] = useState([]);
  const [pageArray, setPageArray] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    console.log("clublist: ", clubList);
    if(!isRead){
      getClubList();
    }
   
  });

  const onYesDelete = (e) => {
    const selectedClub = clubList[global.deleteIndex];
    const clubId = selectedClub.clubId;
    console.log("clubId: ", clubId);

    adminApi.deleteClub({club_id: clubId})
      .then((response) => {

      console.log("delete reponse: ");
      console.log(response);

      if(response.status === 200){
          // helper.showToast('Success', 'Successfully deleted.', 1);
          window.location.reload();
          

      }else {
         
          helper.showToast('Error', 'Failed to delete.', 3);
      }
    })
    .catch((error) => {
        console.log("err: ", error);
        session.set('isLogin', false);
        helper.showToast('Error', 'Invalid auth', 3);
    })
  
  }

  const getClubList = () => {
    setIsRead(true)
    adminApi.getClubList({})
    .then((response) => {

    console.log("clublist reponse: ");
    console.log(response);

    if(response.status === 200){
        // helper.showToast('Success', 'Successfully get.', 1);
        // setClubList(response.data);
        let tempClubList = [];
        let tempItem;
        const clubData = response.data;
        for(let i = 0; i < clubData.length; i++){
          if(clubData[i].username !== 'admin'){

            let date = moment(clubData[i].created_at).format('l');

            let logoUrl = environments.domain + "storage/upload/images/" +clubData[i].logo_url;

            tempItem = {
              clubId: clubData[i].id,
              name: clubData[i].name,
              email: clubData[i].email,
              username: clubData[i].username,
              password: clubData[i].password,
              latitude: clubData[i].latitude,
              longitude: clubData[i].longitude,
              radius: clubData[i].radius,
              logoUrl: logoUrl,
              timezone: clubData[i].timezone,
              createdAt: date,
              turn: clubData[i].turn,
              heading: clubData[i].heading,
              lati_center: clubData[i].lati_center,
              long_center: clubData[i].long_center,
              radius_center: clubData[i].radius_center,
              map: clubData[i].map,
              group: clubData[i].group,
              some2: clubData[i].some2,
              some3: clubData[i].some3

            }

            tempClubList.push(tempItem);
          }
        }

        const tempArray = [...tempClubList].sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
    
          // names must be equal
          return 0;
        });

        // setClubList(tempArray);

        let tempSortedList = [];
        for(let i = 0; i < tempArray.length; i++){
          console.log("number>>: ", i);
          if(clubData[i].username !== 'admin'){

         
            tempItem = {
              number: i,
              clubId: tempArray[i].clubId,
              name: tempArray[i].name,
              email: tempArray[i].email,
              username: tempArray[i].username,
              password: tempArray[i].password,
              latitude: tempArray[i].latitude,
              longitude: tempArray[i].longitude,
              radius: tempArray[i].radius,
              logoUrl: tempArray[i].logoUrl,
              timezone: tempArray[i].timezone,
              createdAt: tempArray[i].createdAt,
              turn: tempArray[i].turn,
              heading: tempArray[i].heading,
              lati_center: tempArray[i].lati_center,
              long_center: tempArray[i].long_center,
              radius_center: tempArray[i].radius_center,
              map: tempArray[i].map,
              group: tempArray[i].group,
              some2: tempArray[i].some2,
              some3: tempArray[i].some3

            }

            tempSortedList.push(tempItem);
          }
        }

  

        setClubAllList(tempSortedList);

        configurePage(tempSortedList, currentPage);
      

        // window.location.reload();
        

        }else {
        
            helper.showToast('Error', 'Failed to get.', 3);
        }
    })
    .catch((error) => {
        console.log("err: ", error);
      
        helper.showToast('Error', 'Invalid auth', 3);
    })
  }

  const configurePage = (list, page) => {
    const listCount = 7;
    const startPage = (page -1 ) * listCount;
    let endPage = (page) * listCount;
    if(list.length < endPage){
      endPage = list.length;
    }
    console.log("start & end index: ", startPage, endPage);
    const pageCount = list.length / listCount;
    console.log("pageCount: ", list);

    let pageArray = [];
    for(let i = 0; i < pageCount; i++){
      let page = i + 1;
      pageArray.push(page);
    }

    setPageArray(pageArray);

    let clubData = list;
    let tempClubList = [];
    let tempItem;
    for(let i = startPage; i < endPage; i++){
      console.log("number: ", clubData[i].number);
      if(clubData[i].number > 0){
        tempItem = {
          number: clubData[i].number,
          clubId: clubData[i].clubId,
          name: clubData[i].name,
          email: clubData[i].email,
          username: clubData[i].username,
          password: clubData[i].password,
          latitude: clubData[i].latitude,
          longitude: clubData[i].longitude,
          radius: clubData[i].radius,
          logoUrl: clubData[i].logoUrl,
          timezone: clubData[i].timezone,
          createdAt: clubData[i].createdAt,
          turn: clubData[i].turn,
          heading: clubData[i].heading,
          lati_center: clubData[i].lati_center,
          long_center: clubData[i].long_center,
          radius_center: clubData[i].radius_center,
          map: clubData[i].map,
          group: clubData[i].group,
          some2: clubData[i].some2,
          some3: clubData[i].some3
  
        }
  
        tempClubList.push(tempItem);
      }

    }
    console.log("tempClubList: ", tempClubList);
    setClubList(tempClubList);

  }

  const goAddClub = () => {
    history.push('/admin/addClub');
  };

  const deleteClub = (e) => {

    setAlertShow(true);

    const index = e.target.id;
    console.log("delete index: ", index);
    global.deleteIndex = index;
    

  }

  const editClub = (e) => {
    const index = e.target.id;
    const selectedClub = clubList[index];
    console.log("selectedClub: ", selectedClub);

    history.push('/admin/editclub', {club: selectedClub});
  }

  const editGroup = (e) => {
    const index = e.target.id;
    const selectedClub = clubList[index];
    console.log("selectedClub: ", selectedClub);

    history.push('/admin/editgroup', {club: selectedClub});
  }

  const goDetail = e => {
    const index = e.target.id;
    const selectedClub = clubList[index];
    console.log("selectedClub: ", selectedClub);

    history.push('/admin/editbox', {club: selectedClub});
  }

  const goDashboard = e => {
    const index = e.target.id;
    const selectedClub = clubList[index];
    console.log("selectedClub: ", selectedClub);

    history.push('/admin/dashboard', {club: selectedClub});
  }

  const onClickPage = (e) => {

    let target = e.target;
    console.log("tempPage: ", target.innerText);
    let tempPage = Number(target.innerText);
    configurePage(clubAllList, tempPage);
    setCurrentPage(tempPage);
  }

  return (
    <>

      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={alertShow}
        toggle={() => setAlertShow(true)}
      >
        <div className="modal-header">
          {/* <h6 className="modal-title" id="modal-title-notification">
            Your attention is required
          </h6> */}
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setAlertShow(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h4 className="heading mt-2">ATTENTION!</h4>
            <div className="text-white">
            Do You Want to Delete This Club?
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button className="btn-white" color="default" type="button"  onClick={() => onYesDelete()}>
            Yes
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setAlertShow(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Header clubCount = {clubList.length}/>
      {/* Page content */}
      <Container className="mt--7" fluid >
         <Row>
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="text-white mb-0">Club List</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="info"
                      onClick={() =>goAddClub()}
                      size="sm"
                    >
                    
                      Add Club
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">ID</th>
                    {/* <th scope="col" /> */}
                    <th scope="col">Name</th>
                    <th scope="col">Latitude</th>
                    <th scope="col">Longitude</th>
                    <th scope="col">Heading</th>
                    <th scope="col">Display Map?</th>
                    <th scope="col">Radius</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Password</th>
                    <th scope="col">Timezone</th>
                    <th scope="col"> Exist Turn?</th>
                    <th scope="col"> Exist Group?</th>
                    <th scope="col"> 
                      <div className="row">
                        Center
                      </div>
                      <div className="row">
                        Latitude
                      </div>
                    </th>
                    <th scope="col">
                      <div className="row">
                        Center
                      </div>
                      <div className="row">
                        Longitude
                      </div>
                    </th>
                    <th scope="col">
                      <div className="row">
                        Center
                      </div>
                      <div className="row">
                        Radius
                      </div>
                    </th>
                    <th scope="col">
                      <div className="row">
                        Date of
                      </div>
                      <div className="row">
                        Publish
                      </div>
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    clubList.length > 0 ?
                      clubList.map((item, index) => 
                      
                          <tr key = {index.toString()}>
                          <th>
                            {item.number}
                          </th>
                          <td scope="row">
                            <Media className="align-items-center">
                              <a
                                className=" mr-4"
                              >
                                <img
                                  alt="..."
                                  src = {item.logoUrl}
                                  className="img-thumbnail"
                                  style={{width: "80px"}}
                                />
                              </a>
                             
                                <span className=" text-sm">
                                {item.name}
                                </span>
                           
                            </Media>
                          </td>

                          {/* <td>{item.name}</td> */}
                       
                          <td>{item.latitude}</td>
                          <td>{item.longitude}</td>
                          <td>{item.heading}</td>
                          <td>{item.map == "true" ? "Yes" : "No"}</td>
                          <td>{item.radius}</td>
                          <td>{item.username}</td>
                          <td>{item.password}</td>
                          <td>UTC {item.timezone}</td>
                          <td>{item.turn == "true" ? "Yes" : "No"}</td>
                          <td>{item.group == "true" ? "Yes" : "No"}</td>
                          <td>{item.lati_center}</td>
                          <td>{item.long_center}</td>
                          <td>{item.radius_center}</td>
                          <td>{item.createdAt}</td>
      
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  id={index}
                                  onClick={(e) => goDashboard(e)}
                                >
                                  Dashboard
                                </DropdownItem>
                                <DropdownItem
                                  id={index}
                                  onClick={(e) => goDetail(e)}
                                >
                                  Detail
                                </DropdownItem>
                                {
                                  item.group == "true" ?
                                    <DropdownItem
                                      id={index}
                                      onClick={(e) => editGroup(e)}
                                    >
                                      Group Size
                                    </DropdownItem>
                                    :
                                    <></>
                                }
                                
                                <DropdownItem
                                  id={index}
                                  onClick={(e) => editClub(e)}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  id={index}
                                  onClick={(e) => deleteClub(e)}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                    
                      )

                      :

                      <></>
                    
                  }
             
                </tbody>

                <thead className="thead-dark">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"> </th>
                    <th scope="col"></th>
                    <th scope="col"> </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">  </th>
                    <th scope="col">  </th>
                    <th scope="col">  </th>
                    <th scope="col">  </th>
                    <th scope="col">  </th>
                    <th scope="col" />
                  </tr>
                </thead>
              </Table>
              <CardFooter className="bg-transparent border-0">
          
                <Pagination
                  className="pagination justify-content-end"
                  listClassName="pagination-sm"
                >
                  {
                    pageArray.length > 0 ?
                    pageArray.map((item, index) => 

                    item == currentPage ? 
                    <PaginationItem className= "active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(item)=>onClickPage(item)}
                        tabIndex={index}
                      >
                        {item}
                      </PaginationLink>
                    </PaginationItem>
                    :
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(index)=>onClickPage(index)}
                        tabIndex={index}
                      >
                  
                        {item}
                      </PaginationLink>
                    </PaginationItem>
                    )
                    :
                    <></>
                  }

                </Pagination>
             
              </CardFooter>
            </Card>
          </div>
        </Row>
     
      </Container>
    </>
  );
};

export default Dashboard;