import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { ReactNotifications } from 'react-notifications-component'
// core components

import AccuRangeMap from "../views/AccuRangeMap";

const AccuRangeMapLayout = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  return (

      <div className="main-content" ref={mainContent}>
        <Switch>
            <Route
              exact path = "/accurange/index"

            >
            <AccuRangeMap />
            </Route>
            <Redirect from="*" to="/accurange/index" />
        </Switch> 
      </div>

  );
};

export default AccuRangeMapLayout;
